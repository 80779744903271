import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ResourceHubPage } from '../ResourceHubPage';
import { MatchParams } from '../hooks';

import BrochureResourceHub from './BrochureResourceHub/BrochureResourceHub';

type ResourceHubPageProps = RouteComponentProps<MatchParams>;
export const BrochureResourceHubChairpersonPage: React.FC<ResourceHubPageProps> = props => (
  <ResourceHubPage { ...props }>
    {data => data.userIsChairperson && (
      <BrochureResourceHub />
    )}
  </ResourceHubPage>
);

