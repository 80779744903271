import * as React from 'react';
import {
  Box, Flex, Heading, Text,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import { CartListItem, EmptyCartCaesar } from 'ui/cart';
import { FundraiserTracker } from 'ui/fundraiser/FundraiserTracker';
import {
  useCartState, useCartDispatch, modifyCart, CartItem,
} from 'features/cart';
import { useQueryParam } from 'features/common';
import { OrderSummary, CheckoutDisabledModal, CheckoutNoFundraiserModal } from 'ui/checkout';
import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';
import { useCartContainsIneligibleProducts } from 'features/cart/hooks';
import { InlineLinkParseAndReplace } from 'ui/components';

// eslint-disable-next-line max-lines-per-function
const Cart = () => {
  const [ checkoutError ] = useQueryParam('error');
  const cartState = useCartState();
  const cartDispatch = useCartDispatch();
  const [ t ] = useTranslation();
  const { fundraiserType } = useFundraiserState();

  const showIneligibleCartItemWarning = useCartContainsIneligibleProducts();
  const onProductQuantityChange = (item: CartItem, quantity: number) => {
    modifyCart(cartDispatch, item.ProductId, quantity);
  };

  return (
    <div>
      {checkoutError === 'nocheckout' && <CheckoutDisabledModal /> }
      {checkoutError === 'nofundraiser' && <CheckoutNoFundraiserModal />}
      <FundraiserTracker />
      <Heading mb="40px">
        {cartState.totalQuantity === 1
          ? `${ t('cart.YourCartTitle') } (${ cartState.totalQuantity } ${ t('cart.Item') })`
          : `${ t('cart.YourCartTitle') } (${ cartState.totalQuantity } ${ t('cart.Items') })`}
      </Heading>
      {showIneligibleCartItemWarning && (
        <Text sx={ { mb: 4, fontSize: '16px' } } variant="text.warning">
          {t('cart.CartHasIneligibleItems')}
        </Text>
      )}
      <Flex sx={ { flexDirection: [ 'column', 'column', 'row' ] } }>

        <Box sx={ { width: '100%' } }>
          {cartState.cart.items.map(item => (
            <CartListItem
              cartItem={ item }
              key={ `${ item.ProductId }-${ item.Quantity }` }
              onQuantityChange={ onProductQuantityChange }
            />
          ))}
          { cartState.cart.items.length === 0 && (
            <EmptyCartCaesar />
          )}
        </Box>

        { cartState.cart && cartState.cart.items.length > 0 && (
          <OrderSummary
            cartItems={ cartState.cart.items }
            fundraiserType={ fundraiserType }
            subtotal={ cartState.subtotal }
            type="cart"
          />
        )}
      </Flex>
      <Text sx={ { fontFamily: 'Roboto', fontWeight: 'bold', mt: [ 0, '12px' ] } }>
        <InlineLinkParseAndReplace text={ t('cart.Disclaimer') } />
      </Text>
    </div>
  );
};

export default Cart;
