/* eslint-disable max-lines-per-function */
import React from 'react';
import {
  Redirect, Switch, useHistory, useRouteMatch,
} from 'react-router-dom';

import { Start } from './LandingPage/components/Start';
import { Calculate } from './LandingPage/components/Calculate';
import { MealDealHowItWorks } from './HowItWorks';
import { MealDealRegistrationPage } from './RegistrationPage';
import { MealDealCreatePage } from './CreatePage';
import { MealDealSuccessPage } from './SuccessPage';

import { AuthenticatedRoute, RouteWrapper } from 'ui/routing';
import { LayoutWithoutFooter, NoLayout } from 'ui/common/Layouts/Layouts';
import { Account, MealDealLoginPage } from 'ui/account';
import { Landing } from 'pages/landing';
import { LandingConfirmation } from 'ui/components';

export const MealDealSellerRoutes: React.FC = () => {
  const history = useHistory();
  const { url } = useRouteMatch();

  //url = start-a-fundraiser/meal-deal/
  return (
    <div data-testid="meal-deal-seller-routes">
      <Switch>
        <RouteWrapper
          component={ Calculate }
          exact={ true }
          history={ history }
          layout={ NoLayout }
          path={ `${ url }` }
          seo={ { pageTitle: 'Start a Fundraiser | Little Caesars Fundraising' } }
        />
        <RouteWrapper
          component={ Start }
          exact={ true }
          history={ history }
          layout={ NoLayout }
          path={ `${ url }/start` }
          seo={ { pageTitle: 'Start a Fundraiser | Little Caesars Fundraising' } }
        />
        <RouteWrapper
          component={ Calculate }
          exact={ true }
          history={ history }
          layout={ NoLayout }
          path={ `${ url }/calculate` }
          seo={ { pageTitle: 'Start a Fundraiser | Little Caesars Fundraising' } }
        />
        <RouteWrapper
          component={ Account }
          exact={ true }
          history={ history }
          layout={ LayoutWithoutFooter }
          path={ `${ url }/account` }
          seo={ { pageTitle: 'Create Account | Little Caesars Fundraising' } }
        />
        <RouteWrapper
          component={ MealDealLoginPage }
          exact={ true }
          history={ history }
          layout={ LayoutWithoutFooter }
          path={ `${ url }/signin` }
          seo={ { pageTitle: 'Sign In | Little Caesars Fundraising' } }
        />
        <RouteWrapper
          component={ MealDealRegistrationPage }
          exact={ true }
          history={ history }
          layout={ LayoutWithoutFooter }
          path={ `${ url }/create-account` }
          seo={ { pageTitle: 'Create Account | Little Caesars Fundraising' } }
        />
        <AuthenticatedRoute
          component={ MealDealCreatePage }
          exact={ true }
          history={ history }
          layout={ LayoutWithoutFooter }
          path={ `${ url }/create` }
          seo={ { pageTitle: 'Start a Fundraiser | Little Caesars Fundraising' } }
        />
        <RouteWrapper
          component={ MealDealSuccessPage }
          exact={ true }
          history={ history }
          layout={ LayoutWithoutFooter }
          path={ `${ url }/create/:supportGuid/success` }
          seo={ { pageTitle: 'Start a Fundraiser | Little Caesars Fundraising' } }
        />
        <RouteWrapper
          component={ MealDealHowItWorks }
          exact={ true }
          history={ history }
          layout={ LayoutWithoutFooter }
          path={ `${ url }/how-it-works` }
          seo={ { pageTitle: 'How It Works | Little Caesars Fundraising' } }
        />
        <RouteWrapper
          component={ Landing }
          exact={ true }
          history={ history }
          layout={ LayoutWithoutFooter }
          path={ `${ url }/:page` }
          seo={ { noTitle: true } }
        />
        <RouteWrapper
          component={ LandingConfirmation }
          exact={ true }
          history={ history }
          layout={ LayoutWithoutFooter }
          path={ `${ url }/:page/confirmation` }
          seo={ { pageTitle: 'Thank you! Thank you! | Little Caesars Fundraising' } }
        />
        <Redirect to="/404" />
      </Switch>
    </div>
  );
};
