import { useTranslation } from '@lce/i18n';
import { Button, Image } from '@lce/slice_v2';
import React from 'react';
import { toast } from 'react-toastify';
import useClipboard from 'react-use-clipboard';
import { ThemeUIStyleObject } from 'theme-ui';

import copyIcon from '../../assets/copypaste.svg';

import { GenericToast } from 'ui/common';

interface ICopyTextButton {
  copyText: string;
  sx: ThemeUIStyleObject;
  buttonText: string;
}

const CopyTextButton: React.FC<ICopyTextButton> = ({ copyText, sx, buttonText }) => {
  const [ t ] = useTranslation();
  const [ , setCopied ] = useClipboard(copyText);
  const onCopyClick = () => {
    setCopied();
    toast(<GenericToast text={ t('common.TextCopied') } />, { position: 'top-center' });
  };
  return (
    <Button data-testid="copy-text-button" onClick={ onCopyClick } sx={ { ...sx } }>
      {buttonText}
      <Image src={ copyIcon } sx={ { width: '13px', ml: '10px' } } />
    </Button>
  );
};

export default CopyTextButton;
