/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import {
  Box,
  Flex,
  Text,
  Image, Popover as SlicePopover,
} from '@lce/slice_v2';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from '@lce/i18n';
import { ThemeUIStyleObject } from 'theme-ui';

import * as FundraiserDefaults from 'constants/FundraiserDefaults';
import UserAccountIcon from 'assets/icons/LCF-Account-Icon.svg';
import { RouterLink } from 'ui/common/RouterLink';
import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';
export interface IAccountOptions {
  roles: string[];
  isLoggedIn: boolean;
  sx?: ThemeUIStyleObject;
}

interface IRenderLinkProps {
  text: string;
  path: string;
  onClick: () => void;
}

const elevatedRoles = [
  FundraiserDefaults.SuperAdminRoleName,
  FundraiserDefaults.ChairpersonRoleName,
  FundraiserDefaults.SalesAgentRoleName,
  FundraiserDefaults.SellerRoleName,
];

const RenderLinkItem: React.FC<IRenderLinkProps> = ({ text, path, onClick }) => (
  <RouterLink
    asNav={ true }
    data-testid={ `${ text }-accountOptionLinks` }
    onClick={ onClick }
    sx={ {
      '&:hover': {
        '& > div > div': {
          color: 'primaryOrange',
        },
      },
    } }
    to={ path }
    variant="links.accountOptionLinks"
  >
    <Flex
      data-testid="flex-renderlinkitem"
      sx={ {
        alignItems: 'center',
        flexDirection: 'row',
      } }
    >
      <Text
        variant="text.accountOptionLinksText"
      >
        {text}
      </Text>
    </Flex>
  </RouterLink>
);

const AccountOptionsDesktop: React.FC<IAccountOptions> = ({
  roles, isLoggedIn,
}) => {
  const [ t ] = useTranslation();
  const { fundraiserType } = useFundraiserState();
  const loginUrl = t('nav.LoginUrl', { context: fundraiserType });
  const showFundraiserRoute = roles && elevatedRoles.filter(x => roles.includes(x)).length > 0;

  const [ isOpen, setIsOpen ] = useState<boolean>(false);
  const handleOnClose = () => setIsOpen(false);
  const handleOnOpen = () => setIsOpen(true);

  return (
    <SlicePopover
      clickContent={ (
        <Flex
          data-testid="accountOptionIcon"
          sx={ { alignItems: 'baseline' } }
        >
          <Box
            sx={ {
              mr: '-8px',
              cursor: 'pointer',
              display: [ 'none', 'inline-block', 'inline-block' ],
            } }
          >
            <Image alt="Account Options Icon" src={ UserAccountIcon } sx={ { height: '32px', width: '26px' } } />
          </Box>
        </Flex>
      ) }
      closeOnClickOutside={ true }
      closeOnEsc={ true }
      isOpen={ isOpen }
      onClose={ handleOnClose }
      onOpen={ handleOnOpen }
      placement="bottom-end"
    >
      <Box
        sx={ {
          w: '256px', mt: [ '26px' ],
          bg: 'white',
          textAlign: 'center',
          borderRadius: '4px',
          boxShadow: '0 0 4px 1px rgba(0, 0, 0, 0.15)',
          border: 'solid 1px #ddd',
          backgroundColor: ' #fff',
          '&:hover': { cursor: 'default' },
        } }
      >
        <Flex
          as="nav" role="navigation"
          sx={ { flexDirection: 'column', flex: '1 1 auto' } }
        >
          { !isLoggedIn
            ? <RenderLinkItem onClick={ handleOnClose } path={ loginUrl } text={ `${ t('nav.Login') }` } />
            : (
              <>
                {showFundraiserRoute &&
                <RenderLinkItem onClick={ handleOnClose } path="/dashboard/fundraisers" text={ `${ t('dashboard.MyFundraisers') }` } />}
                <RenderLinkItem onClick={ handleOnClose } path="/dashboard/my-orders" text={ `${ t('dashboard.MyOrders') }` } />
                {
                  roles.includes(FundraiserDefaults.SuperAdminRoleName) && <RenderLinkItem onClick={ handleOnClose } path="/admin/create-sales-agent" text={ `${ t('dashboard.Administration') }` } />
                }
                <RenderLinkItem onClick={ handleOnClose } path="/dashboard/settings" text={ `${ t('dashboard.Settings') }` } />
                {
                  roles.includes(FundraiserDefaults.SuperAdminRoleName) &&
                  <RenderLinkItem onClick={ handleOnClose } path="/dashboard/reports" text={ `${ t('dashboard.Reports') }` } />
                }
                <RenderLinkItem onClick={ handleOnClose } path="/logout" text={ `${ t('dashboard.Logout') }` } />
              </>
            )}
        </Flex>
      </Box>
    </SlicePopover>
  );
};

export default AccountOptionsDesktop;
