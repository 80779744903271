/* eslint-disable max-lines-per-function */
import { Container, Text, Flex } from '@lce/slice_v2';
import React from 'react';
import { RouteProps } from 'react-router-dom';
import { useTranslation } from '@lce/i18n';
import _map from 'lodash/map';

import FeatureImage from 'assets/images/fundraising-support-background.webp';
import Register from 'assets/icons/Register.svg';
import GetWordOut from 'assets/icons/GetWordOut.svg';
import DollarSign from 'assets/icons/DollarSign.svg';
import { DESKTOP_HEADER_HEIGHT } from 'ui/common/Header/Desktop';
import { MOBILE_HEADER_HEIGHT } from 'ui/common/Header/Mobile';
import { IconSteps, IconStepsProps } from 'ui/components';
import FundraiserProgramCards from 'ui/home/FundraiserPrograms/FundraiserProgramCards';

type HowItWorksPageProps = RouteProps;

const HowItWorks: React.FC<HowItWorksPageProps> = () => {
  const [ t ] = useTranslation();

  const IconMap = {
    Register: Register,
    GetWordOut: GetWordOut,
    DollarSign: DollarSign,
  };

  const steps: IconStepsProps[] = t('howItWorks.steps', { returnObjects: true });

  return (
    <Container
      data-testid="how-it-works-page"
      sx={ { pt: [ MOBILE_HEADER_HEIGHT, MOBILE_HEADER_HEIGHT, MOBILE_HEADER_HEIGHT, DESKTOP_HEADER_HEIGHT ] } }
    >
      <Flex
        data-testid="hero-image"
        sx={ {
          alignItems: 'center',
          background: `linear-gradient(rgba(255, 96, 0, 0.85),rgba(255, 96, 0, 0.85)), 
            url(${ FeatureImage }) no-repeat center center`,
          backgroundSize: 'cover',
          color: 'white',
          flexDirection: 'column',
          gap: [ '16px', '32px' ],
          p: [ '100px 16px 50px', '150px 32px', '200px 64px' ],
          textAlign: 'center',
          width: '100%',
        } }
      >
        <Text variant="home.heading">
          {t('howItWorks.header')}
        </Text>
      </Flex>
      <Flex
        data-testid="fundraiser-programs"
        sx={ {
          flexDirection: 'column',
          gap: '16px',
          maxWidth: '1280px',
          mt: [ '-64px', '-96px', '-152px' ],
          mx: 'auto',
          p: [ '32px 16px', '32px' ],
          position: 'relative',
          width: '100%',
          zIndex: 2,
        } }
      >
        <FundraiserProgramCards />
        <Text variant="disclaimer">
          {t('howItWorks.disclaimer')}
        </Text>
      </Flex>
      <Flex
        sx={ {
          alignItems: 'center',
          flexDirection: 'column',
          gap: [ '16px', '32px' ],
          px: [ '16px', '32px', '64px' ],
          width: '100%',
          maxWidth: '1280px',
          mx: 'auto',
        } }
      >
        <Flex
          sx={ {
            flexDirection: 'column',
            gap: '8px',
            textAlign: 'center',
            textWrap: 'balance',
          } }
        >
          <Text variant="home.subheading">
            {t('howItWorks.asEasyAs')}
          </Text>
          <Text variant="home.subdescription">
            {t('howItWorks.asEasyAsDescription')}
          </Text>
        </Flex>
        <Flex
          sx={ {
            alignItems: 'center',
            flexDirection: 'column',
            gap: '32px',
            maxWidth: '640px',
          } }
        >
          {_map(steps, (step, index) => <IconSteps { ...step } iconSrc={ IconMap[step.iconSrc] } key={ index } />)}
        </Flex>
      </Flex>
    </Container>
  );
};

export default HowItWorks;
