/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import {
  Box,
  Card, Flex, Image, Text,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import DownloadIcon from '../assets/vector.svg';

export interface IImageDownloadCard {
  imageSrc: string;
  name?: string;
}
export const ImageDownloadCard: React.FC<IImageDownloadCard> = ({ imageSrc, name }) => {
  const [ isHovered, setIsHovered ] = useState(false);
  const [ t ] = useTranslation();
  const imageText = t(`ResourceHub.BrochureHub.DownloadableGraphics.${ name }`);

  return (
    <Flex
      sx={ {
        flexDirection: 'column', height: 'fit-content', width: 'fit-content', breakInside: 'avoid', mb: '24px',
      } }
    >
      <Card
        as="a"
        data-testid={ `${ name }-image-download-card` }
        download={ true }
        href={ imageSrc }
        onMouseEnter={ () => setIsHovered(true) }
        onMouseLeave={ () => setIsHovered(false) }
        sx={ {
          position: 'relative',
          borderRadius: '10px',
          border: 'solid 1px #c4c4c4',
          overflow: 'hidden',
          minHeight: '150px',
          alignContent: 'center',
          mb: '4px',
        } }
      >
        <Image
          alt={ imageText }
          src={ imageSrc }
          sx={ {
            display: 'block',
            border: '10px',
          } }
        />
        { isHovered && (
          <Box
            sx={ {
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: 'rgba(35, 31, 32, 0.6)',
            } }
          >
            <Image
              src={ DownloadIcon } sx={ {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                pointerEvents: 'none',
              } }
            />
          </Box>
        )}
      </Card>
      <Text sx={ { fontFamily: 'Lato', fontSize: '14px', fontWeight: 800 } }>
        { imageText }
      </Text>
    </Flex>
  );
};

export default ImageDownloadCard;
