/* eslint-disable max-lines-per-function */
import React from 'react';
import {
  Text, Flex, Image, Link,
} from '@lce/slice_v2';
import _map from 'lodash/map';

import Truck from './assets/brochure-truck.svg';
import Mouse from './assets/digital-mouse.svg';
import Restaurant from './assets/meal-deal-restaurant.svg';

const IconMap = {
  BR: Truck,
  DG: Mouse,
  MD: Restaurant,
};

export interface IFundraiserProgramCard {
  iconSrc: string;
  title: string;
  description: string;
  bulletPoints: string[];
  linkText: string;
  fundraiserCreationUrl: string;
}

const FundraiserProgramCard: React.FC<IFundraiserProgramCard> = ({
  iconSrc,
  title,
  description,
  bulletPoints,
  linkText,
  fundraiserCreationUrl,
}) => (
  <Flex
    data-testid="fundraiser-program-card"
    sx={ {
      alignItems: 'start',
      backgroundColor: '#f7f2ec',
      gap: '16px',
      pr: '16px',
      py: '16px',
      gridArea: iconSrc,
    } }
  >
    <Image
      src={ IconMap[iconSrc] }
      sx={ {
        height: [ '118px', '131px' ],
        maxWidth: [ '90px', '100px' ],
        mr: '8px',
        mt: '16px',
        width: '100%',
      } }
    />
    <Flex
      sx={ {
        flexDirection: 'column',
        gap: '8px',
        height: '100%',
        justifyContent: 'space-between',
        minWidth: '150px',
        textAlign: 'left',
        width: '100%',
      } }
    >
      <Flex sx={ { flexDirection: 'column', gap: '8px' } }>
        <Text
          sx={ { color: 'primary', fontSize: '24px', maxWidth: '130px' } }
          variant="home.card.heading"
        >
          { title }
        </Text>
        <Text variant="home.card.description">
          { description }
        </Text>
        <ul style={ { margin: '0', padding: '0 0 0 16px' } }>
          {_map(bulletPoints, (point, index) => (
            <li key={ index } style={ { fontSize: '14px' } }>
              {point}
            </li>
          ))}
        </ul>
      </Flex>
      <Link
        data-testid={ `${ title }-link` }
        href={ fundraiserCreationUrl }
        sx={ { maxWidth: '192px' } }
        variant="primaryButtonLink"
      >
        {linkText}
      </Link>
    </Flex>
  </Flex>
);

export default FundraiserProgramCard;
