/* eslint-disable max-lines-per-function */
import React from 'react';
import {
  Box, Text, Flex, Image,
} from '@lce/slice_v2';

import { InlineLinkParseAndReplace, TextToLink } from 'ui/components/links/inline-link-parse-and-replace';

export type IconStepsProps = {
  testId?: string;
  iconSrc: string;
  title: string;
  subtitle?: string;
  body: string;
  wordsToLink?: TextToLink[];
}

export const dataTestIdIconSteps = 'icon-steps-component';

export const IconSteps: React.FC<IconStepsProps> = ({
  testId = dataTestIdIconSteps,
  iconSrc,
  title,
  subtitle,
  body,
  wordsToLink,
}) => (
  <Box
    data-testid={ testId }
    sx={ {
      alignItems: 'center',
      columnGap: '16px',
      display: 'grid',
      gridTemplateAreas: [
        '"image title" "body body"',
        '"image title" "image body"',
      ],
      gridTemplateColumns: [ '50px auto', '100px auto' ],
      gridTemplateRows: [ '50px auto', 'auto auto' ],
      rowGap: '8px',
      width: '100%',
    } }
  >
    <Image
      src={ iconSrc }
      sx={ {
        gridArea: 'image',
        height: [ '50px', '100px' ],
        maxWidth: [ '50px', '100px' ],
        width: '100%',
      } }
    />
    <Text
      sx={ {
        color: 'primary', fontSize: '24px', gridArea: 'title', alignSelf: [ 'center', 'end' ],
      } }
      variant="home.card.heading"
    >
      { title }
    </Text>
    <Flex
      sx={ {
        flexDirection: 'column',
        gap: '8px',
        justifyContent: 'center',
        gridArea: 'body',
        alignSelf: 'start',
      } }
    >
      {subtitle && (
        <Text sx={ { fontSize: '18px', lineHeight: 'normal' } } variant="home.card.title">
          {subtitle}
        </Text>
      )}
      <Text sx={ { whiteSpace: 'pre-line' } } variant="home.card.description">
        { wordsToLink
          ? (<InlineLinkParseAndReplace text={ body } wordsToLink={ wordsToLink } />)
          : body }
      </Text>
    </Flex>
  </Box>
);

