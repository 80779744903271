import * as React from 'react';
import { Flex, Image } from '@lce/slice_v2';

import MobileSVG from './LCF-Logo-Mobile.svg';
import MDSVG from './LCF-Logo-Meal-Deal-Desktop.svg';

import DesktopLCFTextSVG from 'assets/images/homepage/LCF.svg';
import DesktopPizzaKitManSvg from 'assets/images/homepage/PizzaKitMan.svg';
import { StatefulLink } from 'ui/components/links';
import { RouterLink } from 'ui/common/RouterLink';

const DesktopLogo: React.FC = () => (
  <StatefulLink
    asNav={ false }
    pathname="/"
    sx={ {
      display: 'flex',
      ml: 40,
      width: '235px',
      alignSelf: 'flex-end',
      WebkitAlignSelf: 'flex-end',
    } }
    variant=""
  >
    <Flex>
      <Image
        alt="Pizza Kit Man"
        src={ DesktopPizzaKitManSvg }
        sx={ {
          position: 'relative',
          top: '17px',
          left: '-7px',
          transform: 'scale(0.7)',
          width: '235px',
        } }
      />
      <Image
        alt="LC Fundraising Logo"
        data-testid="fundraising-desktop-logo"
        src={ DesktopLCFTextSVG }
        sx={ {
          position: 'relative',
          top: '17px',
          right: '48px',
          scale: '2',
        } }
        title="LC Fundraising"
        width="100%"
      />
    </Flex>
  </StatefulLink>
);

const DesktopMDLogo: React.FC = () => (
  <RouterLink
    sx={ {
      display: 'flex',
      ml: 40,
      width: '235px',
      alignSelf: 'flex-end',
      WebkitAlignSelf: 'flex-end',
    } }
    to=""
  >
    <Image
      alt="LC Fundraising Logo"
      data-testid="meal-deal-fundraising-desktop-logo"
      src={ MDSVG }
      title="LC Fundraising"
      width="100%"
    />
  </RouterLink>
);

const MobileLogo: React.FC = () => (
  <StatefulLink
    asNav={ false }
    pathname="/"
    sx={ {
      textAlign: 'center',
      display: 'flex',
      width: '152px',
      justifyContent: 'center',
      WebkitJustifyContent: 'center',
    } }
    variant=""
  >
    <Image
      alt="LC Fundraising Logo"
      data-testid="fundraising-mobile-logo"
      src={ MobileSVG }
      title="LC Fundraising" width="100%"
    />
  </StatefulLink>
);

const MobileMDLogo: React.FC = () => (
  <RouterLink
    sx={ {
      textAlign: 'center',
      display: 'flex',
      width: '152px',
      justifyContent: 'center',
      WebkitJustifyContent: 'center',
    } }
    to=""
  >
    <Image
      alt="LC Fundraising Logo"
      data-testid="meal-deal-fundraising-mobile-logo"
      src={ MDSVG }
      title="LC Fundraising" width="100%"
    />
  </RouterLink>
);

export {
  DesktopLogo,
  DesktopMDLogo,
  MobileLogo,
  MobileMDLogo,
};
