import React, { useReducer } from 'react';
import { useLocation } from 'react-router-dom';

import {
  FundraiserStateContext, State, FundraiserDispatchContext,
} from './fundraiserContext';
import { fundraiserReducer } from './fundraiserReducer';

import { FundraiserType } from 'features/fundraiser/types';

type IFundraiserProviderProps = {
  children: React.ReactNode;
}

// eslint-disable-next-line max-lines-per-function
export const FundraiserProvider = ({ children }: IFundraiserProviderProps) => {
  const { pathname, search } = useLocation();
  const isMealDeal = pathname.includes('/meal-deal') || search.includes('meal-deal');
  const initialState: State = {
    fundraiserId: undefined,
    fundraiserType: isMealDeal ? FundraiserType.MealDeal : FundraiserType.Digital,
    active: 0,
  };

  const [ state, dispatch ] = useReducer(fundraiserReducer, initialState);

  return (
    <FundraiserStateContext.Provider value={ state }>
      <FundraiserDispatchContext.Provider value={ dispatch }>
        { children }
      </FundraiserDispatchContext.Provider>
    </FundraiserStateContext.Provider>
  );
};
