import React from 'react';
import { map as _map } from 'lodash';

import HundredVirtual from '../assets/100Virtual,1000Easy.png';
import BestLunchEver from '../assets/Best Lunch Ever.png';
import BigShoutOut from '../assets/Big Shout Out.jpg';
import PizzaKitRuleOne from '../assets/Pizza Kit Rule1.jpg';
import PizzaOnMobile from '../assets/Pizza on Mobile.png';
import FundraisingWithPizzaKits from '../assets/Little Caesar Fundraising with Pizza Kits and Caesar.png';
import PizzaKitsDelivered from '../assets/Pizza Kits Delivered.png';
import PizzaHeart from '../assets/Pizza heart.png';
import Picasso from '../assets/Pizza_Picasso_485x275.png';
import FundraisingOnDesktop from '../assets/Little Caesar Fundraising on Computer Monitor.png';
import CaesarMagnifyingGlass from '../assets/Caesar Man with Magnifying Glass.png';
import FundraisingMobilePhone from '../assets/Little Caesar Fundraising on Mobile Phone.png';
import Logo from '../assets/Little Caesar Fundraising logo.png';
import LetsTalkPizza from '../assets/Lets Talk Pizza.jpg';
import CaesarClock from '../assets/Caesar Man on Clock.png';
import CaesarCash from '../assets/Caesar Man with Cash.png';
import CaesarTrophy from '../assets/Caesar Man with Trophy.png';
import DoYouSmellPizza from '../assets/Do_You_Smell_Pizza_485x275.png';
import EasiestFundraiserEver from '../assets/EasiestFundraiserEver v2.png';
import FundraiseWithPizza from '../assets/Fundraise with Pizza - You Betcha.png';
import GetTheWordOut from '../assets/Get_The_Word_Out_485x275.png';
import ItsComing from '../assets/Its coming.jpg';
import ItsPizzaTime from '../assets/Its_Pizza_Time_485x275.png';
import JoinOurFundraiser from '../assets/Join Our Fundraiser.jpg';
import ThankYou from '../assets/Thank You 2.png';
import DeserveAHand from '../assets/Deserve_A_Hand.png';
import YayVertical from '../assets/YAY - Clap.jpg';
import YayWide from '../assets/Yay_Wide.png';
import Yum from '../assets/Yum_O_485x275.png';
import { IImageDownloadCard, ImageDownloadCard } from '../ImageDownloadCard';

const ImageGraphicsArr: IImageDownloadCard[] = [
  {
    imageSrc: HundredVirtual,
    name: 'HundredVirtual',
  },
  {
    imageSrc: BestLunchEver,
    name: 'BestLunchEver',
  },
  {
    imageSrc: BigShoutOut,
    name: 'BigShoutOut',
  },
  {
    imageSrc: PizzaKitRuleOne,
    name: 'PizzaKitRuleOne',
  },
  {
    imageSrc: PizzaOnMobile,
    name: 'PizzaOnMobile',
  },
  {
    imageSrc: FundraisingWithPizzaKits,
    name: 'FundraisingWithPizzaKits',
  },
  {
    imageSrc: PizzaKitsDelivered,
    name: 'PizzaKitsDelivered',
  },
  {
    imageSrc: PizzaHeart,
    name: 'PizzaHeart',
  },
  {
    imageSrc: Picasso,
    name: 'Picasso',
  },
  {
    imageSrc: FundraisingOnDesktop,
    name: 'FundraisingOnDesktop',
  },
  {
    imageSrc: CaesarMagnifyingGlass,
    name: 'CaesarMagnifyingGlass',
  },
  {
    imageSrc: FundraisingMobilePhone,
    name: 'FundraisingMobilePhone',
  },
  {
    imageSrc: Logo,
    name: 'Logo',
  },
  {
    imageSrc: LetsTalkPizza,
    name: 'LetsTalkPizza',
  },
  {
    imageSrc: CaesarClock,
    name: 'CaesarClock',
  },
  {
    imageSrc: CaesarCash,
    name: 'CaesarCash',
  },
  {
    imageSrc: CaesarTrophy,
    name: 'CaesarTrophy',
  },
  {
    imageSrc: DoYouSmellPizza,
    name: 'DoYouSmellPizza',
  },
  {
    imageSrc: EasiestFundraiserEver,
    name: 'EasiestFundraiserEver',
  },
  {
    imageSrc: FundraiseWithPizza,
    name: 'FundraiseWithPizza',
  },
  {
    imageSrc: GetTheWordOut,
    name: 'GetTheWordOut',
  },
  {
    imageSrc: ItsComing,
    name: 'ItsComing',
  },
  {
    imageSrc: ItsPizzaTime,
    name: 'ItsPizzaTime',
  },
  {
    imageSrc: JoinOurFundraiser,
    name: 'JoinOurFundraiser',
  },
  {
    imageSrc: ThankYou,
    name: 'ThankYou',
  },
  {
    imageSrc: DeserveAHand,
    name: 'DeserveAHand',
  },
  {
    imageSrc: YayVertical,
    name: 'YayVertical',
  },
  {
    imageSrc: YayWide,
    name: 'YayWide',
  },
  {
    imageSrc: Yum,
    name: 'Yum',
  },
];

export const DisplayAllGraphics = () => (
  <>
    { _map(ImageGraphicsArr, (image, index) => (
      <ImageDownloadCard imageSrc={ image.imageSrc } key={ index } name={ image.name } />
    ))}
  </>
);
