/* eslint-disable max-lines-per-function */
import React, {
  useMemo, useState, useEffect, useCallback,
} from 'react';
import {
  Flex, Grid, Heading, Image, Text, Spinner,
} from '@lce/slice_v2';
import {
  match, Prompt, Redirect, useLocation,
} from 'react-router-dom';
import { useTranslation } from '@lce/i18n';

import {
  ApplyCouponForm, FinalOrderForm, FinalOrderInformation, FinalOrderSummary,
} from './components';

import anouncerCaesarMan from 'assets/images/Announcer-Caesar-Man.png';
import kiteCaesarMan from 'assets/images/caesar-kite.svg';
import { useFetchFundraiserFormData } from 'features/fundraiser/hooks/useFetchFundraiser';
import {
  useBeforeunload,
  useStatefulFetchFundraiserById,
  useSafeDateFormat,
  useFundraiserFinalOrderStatus,
  useRefetch,
} from 'ui/hooks';
import {
  FinalOrderStatus,
  FundraiserListItem,
  Fundraiser,
  FinalOrderFormStepState,
  FinalOrderFormSteps,
  FinalOrderFormStatusFlags,
  ExistingCoupon,
} from 'features/fundraiser/types/fundraiser';
import { KeyValuePairs } from 'ui/components';
import { FundraiserNavigation } from 'ui/fundraiser/FundraiserNavigation';
import { useSessionStorage } from 'features/common/hooks/useSessionStorage';
import * as FundraiserDefaults from 'constants/FundraiserDefaults';
import { useFetchExistingCoupons } from 'features/fundraiser';

interface MatchParams {
  fundraiserId: string;
}

export type FinalOrderPageProps = {
  match: match<MatchParams>;
  testId?: string;
}

export const dataTestIdFinalOrderPage = 'final-order-page-component';

export const FinalOrderPage: React.FC<FinalOrderPageProps> = ({
  match,
  testId = dataTestIdFinalOrderPage,
}) => {
  const fundraiserId = parseInt(match?.params?.fundraiserId);

  const {
    data: fundraiser,
    isLoading: isFundraiserDataLoading,
    isError: fundraiserFetchError,
    refetch: refetchFundraiser,
  } = useStatefulFetchFundraiserById(fundraiserId.toString());

  const RefetchDataDisplay = useRefetch({ refetch: refetchFundraiser });

  const {
    data: fundraiserFormData,
    isLoading: isFormDataLoading,
  } = useFetchFundraiserFormData(fundraiserId);

  const {
    data: existingCoupons,
    isLoading: isCouponsLoading,
  } = useFetchExistingCoupons(fundraiserId);

  if (isFundraiserDataLoading || isFormDataLoading || isCouponsLoading) {
    return (
      <Flex
        data-testid={ testId }
        sx={ {
          alignItems: 'center', height: '100vh', justifyContent: 'center', width: '100%',
        } }
      >
        <Spinner variant="lce" />
      </Flex>
    );
  }

  if (fundraiserFetchError) {
    return (
      <Flex data-testid={ testId } sx={ { flexDirection: 'column', alignItems: 'center', gap: '12px' } }>
        <Image alt="Kite Caesar Man" src={ kiteCaesarMan } />
        <RefetchDataDisplay />
      </Flex>
    );
  }

  return (
    <>
      <FundraiserNavigation fundId={ match.params.fundraiserId } url={ match.url } />
      {fundraiser && (
        <FinalOrderDetails
          existingCoupons={ existingCoupons || [] }
          fundraiser={ fundraiser }
          fundraiserFormData={ fundraiserFormData }
          testId={ testId }
        />
      )}
    </>
  );
};

type FinalOrderDetailsProps ={
  fundraiser: FundraiserListItem;
  fundraiserFormData: Fundraiser | undefined;
  existingCoupons: ExistingCoupon[];
  testId?: string;
}

export type LocationProps = {
  finalOrderSubmitted?: boolean;
}

export const FinalOrderDetails: React.FC<FinalOrderDetailsProps> = ({
  fundraiser, fundraiserFormData, testId, existingCoupons,
}) => {
  const [ t ] = useTranslation();
  const { state } = useLocation<LocationProps>();
  const finalOrderStatus = useFundraiserFinalOrderStatus(fundraiser);

  const formInProgress = finalOrderStatus <= FinalOrderStatus.FinalOrderInProgress &&
    finalOrderStatus > FinalOrderStatus.FinalOrderUpcoming;
  const formSubmitted = finalOrderStatus === FinalOrderStatus.FinalOrderSubmitted;
  const editSubmittedForm = formSubmitted && state?.finalOrderSubmitted;
  const showSteps = formInProgress || editSubmittedForm;

  if (finalOrderStatus <= FinalOrderStatus.FinalOrderUpcoming) {
    return (
      <Flex data-testid={ testId } sx={ { flexDirection: 'column', alignItems: 'center', gap: '12px' } }>
        <Image alt="Caesar Man Announcer" src={ anouncerCaesarMan } sx={ { width: [ '157px', '217px' ] } } />
        <Heading as="h1">
          {t('dashboard.finalOrderPage.pending.header')}
        </Heading>
        <Text sx={ { color: 'black' } }>
          {t('dashboard.finalOrderPage.pending.instructions')}
        </Text>
      </Flex>
    );
  } else if (showSteps) {
    return (
      <FinalOrderSteps
        existingCoupons={ existingCoupons }
        fundraiser={ fundraiser }
        fundraiserFormData={ fundraiserFormData }
        testId={ testId }
      />
    );
  } else {
    return <Redirect to={ `/dashboard/fundraisers/final-order/confirmation/${ fundraiser.Id }` } />;
  }
};

export const FinalOrderSteps: React.FC<FinalOrderDetailsProps> = ({
  fundraiser, fundraiserFormData, testId, existingCoupons,
}) => {
  useBeforeunload(e => e.preventDefault());
  const [ t ] = useTranslation();
  const hasCoupons = existingCoupons.length > 0;
  const finalOrderDate = useSafeDateFormat(fundraiser.FinalOrderDetails?.FinalOrderDate);
  const [ formData, setFormData, removeFormData ] =
    useSessionStorage(FundraiserDefaults.FinalOrderSessionId, {} as FinalOrderFormStatusFlags);

  const [ currentStep, setCurrentStep ] = useState<FinalOrderFormSteps>(FinalOrderFormSteps.Form);
  const allStepsCompleted =
    formData &&
    formData.finalOrderFormFilled &&
    (hasCoupons && formData.applyCouponFormFilled) &&
    formData.finalOrderSummaryFilled &&
    formData.finalOrderInformationFilled;

  const getStepMode = (stepName: FinalOrderFormSteps) => {
    switch (stepName) {
      case currentStep:
        return FinalOrderFormStepState.Active;
      case FinalOrderFormSteps.Form:
        return formData.finalOrderFormFilled ? FinalOrderFormStepState.Preview : FinalOrderFormStepState.Hidden;
      case FinalOrderFormSteps.Coupons:
        return formData.applyCouponFormFilled ? FinalOrderFormStepState.Preview : FinalOrderFormStepState.Hidden;
      case FinalOrderFormSteps.Summary:
        return formData.finalOrderSummaryFilled ? FinalOrderFormStepState.Preview : FinalOrderFormStepState.Hidden;
      case FinalOrderFormSteps.Information:
        return formData.finalOrderInformationFilled ? FinalOrderFormStepState.Preview : FinalOrderFormStepState.Hidden;
      default:
        return FinalOrderFormStepState.Hidden;
    }
  };

  const updateCurrentStep = useCallback((formData: FinalOrderFormStatusFlags) => {
    const stepsConditions = [
      { condition: !formData.finalOrderFormFilled, step: FinalOrderFormSteps.Form },
      { condition: !formData.applyCouponFormFilled && hasCoupons, step: FinalOrderFormSteps.Coupons },
      { condition: !formData.finalOrderSummaryFilled, step: FinalOrderFormSteps.Summary },
      { condition: !formData.finalOrderInformationFilled, step: FinalOrderFormSteps.Information },
    ];

    if (allStepsCompleted) {
      setCurrentStep(FinalOrderFormSteps.Information);
      return;
    }

    for (const { condition, step } of stepsConditions) {
      if (condition) {
        setCurrentStep(step);
        break;
      }
    }
  }, [ allStepsCompleted, hasCoupons ]);

  const shouldWarnUserOnNavigate = useMemo(() => !allStepsCompleted, [ allStepsCompleted ]);

  useEffect(() => {
    updateCurrentStep(formData);
  }, [ formData, updateCurrentStep ]);

  const goToNextStep = () => {
    const currentIndex = Object.values(FinalOrderFormSteps).indexOf(currentStep);
    const nextStep = Object.values(FinalOrderFormSteps)[(currentIndex + 1) % Object.values(FinalOrderFormSteps).length];
    setCurrentStep(nextStep as FinalOrderFormSteps);
  };

  const saveData = (stepFilled: FinalOrderFormSteps) => {
    setFormData({
      ...formData,
      [`${ stepFilled }Filled`]: true,
    });
  };

  const handleSubmitFinalOrder = async() => {
    removeFormData();
  };

  useEffect(() => {
    if (allStepsCompleted) {
      handleSubmitFinalOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ allStepsCompleted ]);

  return (
    <Flex data-testid={ testId } sx={ { gap: '12px', flexDirection: 'column', mb: '16px' } }>
      <Heading as="h1">
        {t('dashboard.finalOrderPage.active.header')}
      </Heading>
      <Grid
        sx={ {
          gridTemplateColumns: [ '1FR', 'auto auto' ],
          gridGap: [ 0, '12px' ],
        } }
      >
        <KeyValuePairs
          list={ [
            {
              term: t('dashboard.finalOrderPage.active.details.nameOfGroup'),
              description: fundraiser.Name,
            },
            {
              term: t('dashboard.finalOrderPage.active.details.finalOrderDate'),
              description: finalOrderDate,
            },
            {
              term: t('dashboard.finalOrderPage.active.details.chairperson'),
              description:
                    `${ fundraiserFormData?.Chairperson?.FirstName } ${ fundraiserFormData?.Chairperson?.LastName }`,
            },
          ] }
        />
        <KeyValuePairs
          list={ [
            {
              term: t('dashboard.finalOrderPage.active.details.fundraiserId'),
              description: fundraiser.Id.toString(),
            },
            {
              term: t('dashboard.finalOrderPage.active.details.groupId'),
              description: fundraiser.GroupId.toString(),
            },
            {
              term: t('dashboard.finalOrderPage.active.details.salesAgent'),
              description: fundraiser.SalesAgentName,
            },
          ] }
        />
      </Grid>
      <Text sx={ { color: 'primaryGrayDark' } }>
        {t('dashboard.finalOrderPage.active.instructions')}
      </Text>
      <FinalOrderForm
        fundraiser={ fundraiser }
        mode={ getStepMode(FinalOrderFormSteps.Form) }
        onEdit={ () => setCurrentStep(FinalOrderFormSteps.Form) }
        onNext={ () => {
          saveData(FinalOrderFormSteps.Form);
          goToNextStep();
        } }
      />
      {hasCoupons && (
        <ApplyCouponForm
          existingCoupons={ existingCoupons }
          fundraiserId={ fundraiser.Id }
          mode={ getStepMode(FinalOrderFormSteps.Coupons) }
          onEdit={ () => setCurrentStep(FinalOrderFormSteps.Coupons) }
          onNext={ () => {
            saveData(FinalOrderFormSteps.Coupons);
            goToNextStep();
          } }
        />
      )}
      <FinalOrderSummary
        fundraiserId={ fundraiser.Id }
        mode={ getStepMode(FinalOrderFormSteps.Summary) }
        onEdit={ () => setCurrentStep(FinalOrderFormSteps.Summary) }
        onNext={ () => {
          saveData(FinalOrderFormSteps.Summary);
          goToNextStep();
        } }
        stepNumber={ 2 + Number(hasCoupons) }
      />
      <FinalOrderInformation
        fundraiserId={ fundraiser.Id }
        mode={ getStepMode(FinalOrderFormSteps.Information) }
        onEdit={ () => setCurrentStep(FinalOrderFormSteps.Information) }
        onNext={ () => {
          saveData(FinalOrderFormSteps.Information);
        } }
        stepNumber={ 3 + Number(hasCoupons) }
      />
      <Prompt message={ t('dashboard.finalOrderPage.prompt') } when={ shouldWarnUserOnNavigate } />
    </Flex>
  );
};
