import React from 'react';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from '@lce/i18n';
import { Flex, Text, Button } from '@lce/slice_v2';
import { RefetchOptions, RefetchQueryFilters, QueryObserverResult } from 'react-query';

import { ApiError } from 'features/common';
import { InlineLinkParseAndReplace } from 'ui/components/links';

export type RefetchDataDisplayProps = {
  children?: React.ReactNode;
  testId?: string;
}

export const dataTestIdRefetchDataDisplay = 'refetch-data-display-component';
export const dataTestIdRefetchDataDisplayRetryButton = 'refetch-data-display-component-retry-button';

export interface IRefetchDataDisplayProps {
  maxRetries: number;
  refetch: (options?: (RefetchOptions & RefetchQueryFilters)) =>
  Promise<QueryObserverResult<any, ApiError>>;
  totalRefetchCount: number;
  setTotalRefetchCount: React.Dispatch<React.SetStateAction<number>>;
  fullWidth?: boolean;
  testId?: string;
}

export const RefetchDataDisplay: React.FC<IRefetchDataDisplayProps> =
 ({
   maxRetries,
   refetch,
   totalRefetchCount,
   setTotalRefetchCount,
   fullWidth = true,
   testId = dataTestIdRefetchDataDisplay,
 }) => {
   const [ t ] = useTranslation();

   const handleThisRefetch = () => {
     refetch();
     setTotalRefetchCount(prevCount => prevCount + 1);
   };

   return (
     <Flex
       data-testid={ testId }
       sx={ {
         flexDirection: 'column',
         alignItems: 'center',
         p: [ '16px', '32px' ],
         gap: '16px',
         width: '100%',
       } }
     >
       <Text
         data-testid="error-text"
         sx={ {
           fontWeight: 'bold',
           textWrap: 'balance',
           textAlign: 'center',
         } }
         variant="dashboard.finalOrderPage.body"
       >
         <InlineLinkParseAndReplace
           text={ totalRefetchCount >= maxRetries
             ? t('dashboard.finalOrderPage.FetchErrorMaxRetries')
             : t('dashboard.finalOrderPage.FetchError') }
         />
       </Text>
       {totalRefetchCount < maxRetries && (
         <Button
           data-testid={ dataTestIdRefetchDataDisplayRetryButton }
           onClick={ handleThisRefetch }
           sx={ { width: fullWidth ? '100%' : 'auto' } }
           variant="primary.large"
         >
           {t('dashboard.finalOrderPage.Retry')}
         </Button>
       )}
     </Flex>
   );
 };

