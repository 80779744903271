import React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';
import { useLocation } from 'react-router-dom';

import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';
import { RouterLink } from 'ui/common/RouterLink';

export type StatefulLinkProps = {
  children?: React.ReactNode;
  testId?: string;
  pathname: string;
  asNav?: boolean;
  variant?: string;
  sx?: ThemeUIStyleObject;
  persistSearch?: boolean;
  searchOverride?: string | undefined;
}

export const dataTestIdStatefulLink = 'stateful-link-component';

export const StatefulLink: React.FC<StatefulLinkProps> = ({
  children,
  testId = dataTestIdStatefulLink,
  pathname,
  asNav = true,
  variant = 'links.navRouterLink',
  sx,
  persistSearch,
  searchOverride,
}) => {
  const { fundraiserType } = useFundraiserState();
  const location = useLocation();
  const { search } = location;
  const to = persistSearch
    ? { pathname, search: searchOverride ?? search, state: { fundraiserType } }
    : { pathname, state: { fundraiserType } };

  return (
    <RouterLink
      asNav={ asNav }
      data-testid={ testId }
      sx={ sx }
      to={ to }
      variant={ variant }
    >
      { children }
    </RouterLink>
  );
};
