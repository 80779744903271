import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Flex, Image } from '@lce/slice_v2';

import { LoginForm } from 'ui/account/LoginForm';
import JumpingCaesar from 'assets/images/jumping-caesar.png';

interface IMealDealLoginPageProps {
  onLoginRedirectUri?: string;
}

export const MealDealLoginPage: React.FC<IMealDealLoginPageProps> = ({
  onLoginRedirectUri = '/start-a-fundraiser/meal-deal/create',
}) => {
  const history = useHistory();

  return (
    <Flex
      sx={ {
        mt: [ 0, '68px' ],
        height: '562px',
        flexDirection: [ 'column', 'row' ],
        justifyContent: 'center',
      } }
    >
      <Box
        sx={ {
          width: '274px', my: 'auto', mr: '100px', display: [ 'none', 'none', 'block' ],
        } }
      >
        <Image alt="LC Fundraising Logo" src={ JumpingCaesar } title="LC Fundraising" />
      </Box>

      <Flex sx={ { flexDirection: 'column', maxWidth: 'none' } }>
        <LoginForm
          onBackCallback={ () => history.goBack() }
          onLoginRedirectUri={ onLoginRedirectUri }
          redirect={ true }
        />
      </Flex>

    </Flex>
  );
};

