import * as React from 'react';
import {
  Flex, Box, Button,
} from '@lce/slice_v2';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '@lce/i18n';

import { Cart } from '../Cart';
import Menu from '../Menu';
import { Arch, useIsArchExpanding } from '../Arch';

import { theme } from 'theme';
import { useMediaQuery } from 'features/common';
import { useChooseLogo } from 'features/fundraiser/hooks/useChooseLogo/useChooseLogo';
import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';
import { FundraiserType } from 'features/fundraiser';

export interface IMobileProps {
  isAuthenticated: boolean;
  roles: string[];
  joinFundPage: boolean;
}
export const joinFundPage = window.location.href.includes('join-fundraiser');
export const MOBILE_HEADER_HEIGHT = joinFundPage ? '92px' : '135px';

// eslint-disable-next-line max-lines-per-function
const Mobile: React.FC<IMobileProps> = ({
  isAuthenticated, joinFundPage, roles,
}) => {
  const history = useHistory();
  const [ t ] = useTranslation();
  const isTablet = useMediaQuery(`(min-width: ${ theme.breakpoints[1] } )`);
  const { fundraiserType } = useFundraiserState();
  const startFundraiserUrl = t('nav.StartAFundraiserUrl', { context: fundraiserType });
  const { Logo } = useChooseLogo(fundraiserType, true);
  const showStartAFundraiser = fundraiserType !== FundraiserType.MealDeal;
  const isArchExpanding = useIsArchExpanding();
  const headerHeight = joinFundPage && !isTablet ? '88px' : !showStartAFundraiser ? '88px' : MOBILE_HEADER_HEIGHT;

  return (
    <Box
      data-testid="mobile-header"
      sx={ {
        position: 'fixed',
        top: 0,
        zIndex: 999,
        width: '100vw',
      } }
      width="1"
    >
      <Box
        sx={ {
          height: headerHeight,
          width: '100%',
          bg: 'white',
          boxShadow: !isArchExpanding && '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
          transition: !isArchExpanding && 'box-shadow 2s linear',
          transitionDelay: '0.5s',
        } }
        width="1"
      >
        <Box
          sx={ {
            display: 'grid', gridGap: 3, pt: 6,
            gridTemplateColumns: 'repeat(3, 1fr)', alignItems: 'center',
          } }
        >
          <Menu.Overlay loggedIn={ isAuthenticated } roles={ roles } />
          <Flex
            sx={ {
              alignItems: 'center', justifyContent: 'center', width: '152px', placeSelf: 'center',
            } }
          >
            <Logo />
          </Flex>
          <Flex
            sx={ {
              alignItems: 'center', justifyContent: 'flex-end', mr: 1, px: 8,
            } }
          >
            <Cart />
          </Flex>
        </Box>
        {showStartAFundraiser && (
          <Flex
            sx={ {
              width: '100%', alignItems: 'center',
              justifyContent: 'center', mx: 'auto', px: 4, pt: 2,
            } }
          >
            <Button
              data-testid="startNewfundraiser"
              onClick={ () => history.push(startFundraiserUrl) }
              sx={ {
                mx: 'auto',
                fontSize: '13px',
                width: [ '100%', 'auto' ],
                whiteSpace: 'nowrap',
                display: joinFundPage ? 'none' : 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: '347px',
                px: [ 0, 8, 6, 6, 5 ],
              } }
            >
              START NEW FUNDRAISER
            </Button>
          </Flex>
        )}
      </Box>
      <Arch
        headerHeight={ headerHeight }
      />
    </Box>
  );
};

export default Mobile;
