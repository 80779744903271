// AppInsights.js
import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

import { history } from 'features/common/history';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION_ID || '',
    correlationHeaderExcludedDomains: [ 'cdn.cookielaw.org' ],
    extensions: [ reactPlugin ],
    extensionConfig: {
      [reactPlugin.identifier]: { history },
    },
    distributedTracingMode: DistributedTracingModes.W3C,
    disableFetchTracking: false,
    enableCorsCorrelation: false,
    disableTelemetry: !process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION_ID,
  },
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };
