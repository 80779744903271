/* eslint-disable max-len */
import { useQuery } from 'react-query';

import { fetchNoDeliveryDate } from '../../api';
import { NoDeliveryDate, NoDeliveryDateFormat } from '../../types/fundraiser';
import * as QueryKeys from '../../../../constants/QueryKeys';

import { ApiError } from 'features/common';
const usZipRegEx = (/(^\d{5}$)|(^\d{5}-\d{4}$)/);
const isEnabled = (zipCode: string) => usZipRegEx.test(zipCode);

export const useFetchNoDeliveryDate =
  (zipCode: string) => useQuery<NoDeliveryDate[], ApiError, NoDeliveryDateFormat[]>([ QueryKeys.NoDeliveryDate, zipCode ],
    () => fetchNoDeliveryDate(zipCode), {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: isEnabled(zipCode),
      select: (data) => {
        if (typeof data === 'string') {
          return [];
        }
        const today = new Date();
        data = data?.filter(
          (item: NoDeliveryDate) => new Date(item.StartDate) > today || new Date(item.EndDate) > today
        );
        return data?.map((item: NoDeliveryDate) => ({
          from: new Date(item.StartDate),
          to: new Date(item.EndDate),
        }));
      },
    });
