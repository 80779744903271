import { IGroupSlug } from '../types/groupSlug';
import { IParentGroup } from '../types/parentGroup';

export const groups: IGroupSlug[] = [
  { id: '1', name: 'usa-soccer' },
  { id: '2', name: 'coerversoccercamp' },
  { id: '56', name: 'soccer-in-the-sand' },
  { id: '58', name: 'msysa' },
  { id: '59', name: 'jaguars' },
  { id: '60', name: 'boulder' },
  { id: '61', name: 'psc' },
  { id: '63', name: 'albionsc' },
  { id: '64', name: 'LCAHLTravel' },
  { id: '65', name: 'LCAHLHouse' },
  { id: '66', name: 'CCG' },
  { id: '67', name: 'MichStars' },
  { id: '68', name: 'dsc' },
  { id: '69', name: 'casl' },
  { id: '70', name: 'kick' },
  { id: '71', name: 'Kingdom' },
  { id: '72', name: 'lbk' },
];

export const groupsInfo: IParentGroup[] = [
  {
    parentGroupId: 1,
    groupTypeId: 27,
    groupTypeName: 'Youth Sports Groups - Soccer',
    numberOfSellers: 1,
    salesAgentCode: 90468,
  },
  {
    parentGroupId: 2,
    groupTypeId: 27,
    groupTypeName: 'Youth Sports Groups - Soccer',
    numberOfSellers: 1,
    salesAgentCode: 90468,
  },
  {
    parentGroupId: 56,
    groupTypeId: 27,
    groupTypeName: 'Youth Sports Groups - Soccer',
    numberOfSellers: 1,
    salesAgentCode: 90468,
  },
  {
    parentGroupId: 58,
    groupTypeId: 27,
    groupTypeName: 'Youth Sports Groups - Soccer',
    numberOfSellers: 1,
    salesAgentCode: 90468,
  },
  {
    parentGroupId: 59,
    groupTypeId: 27,
    groupTypeName: 'Youth Sports Groups - Soccer',
    numberOfSellers: 1,
    salesAgentCode: 90468,
  },
  {
    parentGroupId: 60,
    groupTypeId: 27,
    groupTypeName: 'Youth Sports Groups - Soccer',
    numberOfSellers: 1,
    salesAgentCode: 90468,
  },
  {
    parentGroupId: 61,
    groupTypeId: 27,
    groupTypeName: 'Youth Sports Groups - Soccer',
    numberOfSellers: 1,
    salesAgentCode: 90468,
  },
  {
    parentGroupId: 63,
    groupTypeId: 27,
    groupTypeName: 'Youth Sports Groups - Soccer',
    numberOfSellers: 1,
    salesAgentCode: 90468,
  },
  {
    parentGroupId: 64,
    groupTypeId: 24,
    groupTypeName: 'Youth Sports Groups - Hockey',
    numberOfSellers: 100,
    salesAgentCode: 25692,
  },
  {
    parentGroupId: 65,
    groupTypeId: 24,
    groupTypeName: 'Youth Sports Groups - Hockey',
    numberOfSellers: 300,
    salesAgentCode: 25692,
  },
  {
    parentGroupId: 66,
    groupTypeId: 11,
    groupTypeName: 'Gymnastics',
    numberOfSellers: 100,
    salesAgentCode: 25692,
  },
  {
    parentGroupId: 67,
    groupTypeId: 27,
    groupTypeName: 'Youth Sports Groups - Soccer',
    numberOfSellers: 100,
    salesAgentCode: 90468,
  },
  {
    parentGroupId: 68,
    groupTypeId: 27,
    groupTypeName: 'Youth Sports Groups - Soccer',
    numberOfSellers: 100,
    salesAgentCode: 90468,
  },
  {
    parentGroupId: 69,
    groupTypeId: 27,
    groupTypeName: 'Youth Sports Groups - Soccer',
    numberOfSellers: 100,
    salesAgentCode: 90468,
  },
  {
    parentGroupId: 70,
    groupTypeId: 27,
    groupTypeName: 'Youth Sports Groups - Soccer',
    numberOfSellers: 100,
    salesAgentCode: 90468,
  },
  {
    parentGroupId: 71,
    groupTypeId: 27,
    groupTypeName: 'Youth Sports Groups - Soccer',
    numberOfSellers: 100,
    salesAgentCode: 90468,
  },
  {
    parentGroupId: 72,
    groupTypeId: 27,
    groupTypeName: 'Youth Sports Groups - Soccer',
    numberOfSellers: 100,
    salesAgentCode: 90468,
  },
];
