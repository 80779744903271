import React from 'react';
import { useHistory } from 'react-router-dom';
import { ChevronLeft } from 'react-feather';
import { Flex, Text } from '@lce/slice_v2';
export interface IGoBackLinkProps {
  children: React.ReactNode;
}

const GoBackLink: React.FC<IGoBackLinkProps> = ({ children }) => {
  const history = useHistory();

  return (
    <Flex
      as="a" data-testid="go-back-link" onClick={ () => history.goBack() }
      sx={ { alignItems: 'center', cursor: 'pointer' } }
    >
      <ChevronLeft color="#ff671b" height="32px" width="32px" />
      <Text sx={ { color: 'primaryOrange', fontFamily: 'Roboto', fontWeight: 'bold' } }>
        {children}
      </Text>
    </Flex>
  );
};

export default GoBackLink;
