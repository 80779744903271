import React, { useReducer } from 'react';

import { PaymentStateContext, PaymentDispatchContext } from './paymentContext';
import { paymentReducer } from './paymentReducer';

import { PaymentState } from 'ui/checkout';

type IPaymentProviderProps = {
  children: React.ReactNode;
}

export const PaymentProvider = ({ children }: IPaymentProviderProps) => {
  const initialState: PaymentState = {
    card: {
      cvvNum: '',
      expiryMonth: '',
      expiryYear: '',
    }, code: '', inlineError: false, popup: false, showPaymentStep: false, showReviewStep: false, token: '',
  };

  const [ state, dispatch ] = useReducer(paymentReducer, initialState);

  return (
    <PaymentStateContext.Provider value={ state }>
      <PaymentDispatchContext.Provider value={ dispatch }>
        { children }
      </PaymentDispatchContext.Provider>
    </PaymentStateContext.Provider>
  );
};
