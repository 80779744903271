import React from 'react';
import { Box, Container } from '@lce/slice_v2';
import { useOktaAuth } from '@okta/okta-react';

import { DESKTOP_HEADER_HEIGHT } from '../Header/Desktop';
import { MOBILE_HEADER_HEIGHT } from '../Header/Mobile';
import { Footer } from '../Footer';
import { Sidebar } from '../Sidebar';

import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';
import { FundraiserType } from 'features/fundraiser';
import { useMediaQuery } from 'features/common/hooks/useMediaQuery';
import { useUserInfo } from 'features/common/hooks/useUserInfo';

const DashboardLayout = ({ children }) => {
  const isLarge = useMediaQuery('(min-width: 960px)');
  const pathname = window.location.pathname;
  const { authState } = useOktaAuth();
  const { data: user } = useUserInfo(authState.isAuthenticated);
  return (
    <Container
      sx={ {
        display: 'grid',
        gridTemplateRows: [ '1fr 1fr', '1fr 1fr', 'none' ],
        height: '100vh',
        pt: [ MOBILE_HEADER_HEIGHT, MOBILE_HEADER_HEIGHT, MOBILE_HEADER_HEIGHT, DESKTOP_HEADER_HEIGHT ],
      } }
    >
      {isLarge && <Sidebar pathname={ pathname } userInfo={ user } />}
      <Box
        sx={ {
          ml: [ 0, 0, '343px' ],
          maxWidth: '1100px',
          pt: [ 0, 0, '35px' ], mt: [ '15px', '30px', 0 ],
        } }
      >
        {children}
      </Box>
    </Container>
  );
};

const MainLayout = ({ children }) => {
  const { fundraiserType } = useFundraiserState();
  const headerPaddingMobile = fundraiserType === FundraiserType.MealDeal
    ? 'calc(88px + 15px)'
    : `calc(${ MOBILE_HEADER_HEIGHT } + 15px)`;
  const headerPaddingDesktop = `calc(${ DESKTOP_HEADER_HEIGHT } + 35px)`;
  return (
    <Container
      sx={ {
        padding: [
          `${ headerPaddingMobile } 15px 0 15px`,
          `${ headerPaddingMobile } 15px 0 15px`,
          `${ headerPaddingMobile } 15px 0 15px`,
          `${ headerPaddingDesktop } 15px 0 15px`,
        ],
        maxWidth: [ '100%', '650px', '1240px' ],
      } }
    >
      {children}
      <Footer />
    </Container>
  );
};

const LayoutWithoutFooter = ({ children }) => {
  const { fundraiserType } = useFundraiserState();
  const headerPaddingMobile = fundraiserType === FundraiserType.MealDeal
    ? 'calc(88px + 15px)'
    : `calc(${ MOBILE_HEADER_HEIGHT } + 15px)`;
  const headerPaddingDesktop = `calc(${ DESKTOP_HEADER_HEIGHT } + 35px)`;
  return (
    <Container
      sx={ {
        padding: [ `${ headerPaddingMobile } 15px 0 15px`, `${ headerPaddingDesktop } 15px 0 15px` ],
        maxWidth: [ '100%', '650px', '1240px' ],
      } }
    >
      {children}
    </Container>
  );
};

const StartAFundraiserLayout = ({ children }) => (
  <Container
    sx={ {
      maxWidth: '100%',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    } }
  >
    {children}
    <Footer />
  </Container>
);

const HomePageLayout = ({ children }) => (
  <Container
    sx={ {
      maxWidth: '100%',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    } }
  >
    {children}
    <Footer />
  </Container>
);

const LandingPageLayout = ({ children }) => (
  <Container
    sx={ {
      pt: [
        0,
        0,
        '30px',
        0,
      ],
      maxWidth: '100%',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    } }
  >
    {children}
    <Footer />
  </Container>
);

const NoLayout = ({ children }) => children;

export {
  DashboardLayout,
  HomePageLayout,
  MainLayout,
  NoLayout,
  LayoutWithoutFooter,
  LandingPageLayout,
  StartAFundraiserLayout,
};
