/* eslint-disable max-lines-per-function */
import React from 'react';
import { Box, Flex } from '@lce/slice_v2';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { MatchParams, useResourceHub } from '../hooks';

import {
  useFetchFundraiserById,
  FundraiserListItem,
} from 'features/fundraiser';
import { FundraiserNavigation } from 'ui/fundraiser/FundraiserNavigation';

type ResourceHubRenderProps = {
  resourceHubData: string;
  fundraiser: FundraiserListItem | undefined;
  sellerSupportGuid: string | undefined;
  userIsChairperson: boolean;
  isChairpersonResourceHub: boolean;
}

type ResourceHubPageProps = RouteComponentProps<MatchParams> & {
  children: (params: ResourceHubRenderProps) => React.ReactNode;
};

export const ResourceHubPage: React.FC<ResourceHubPageProps> = ({ match, children, ...rest }) => {
  const { data: fundraiser, isLoading: isFundraiserLoading } =
      useFetchFundraiserById(parseInt(match.params.fundraiserId));

  const {
    userIsChairperson, isChairpersonResourceHub, resourceHubData, sellerSupportGuid,
  } =
  useResourceHub({ match, fundraiser, ...rest });

  if (isFundraiserLoading) {
    return null;
  }

  if (!isFundraiserLoading && isChairpersonResourceHub && !userIsChairperson) {
    return <Redirect to="/dashboard/fundraisers" />;
  }

  return (
    <>
      <Flex sx={ { flex: 1 } }>
        <Helmet>
          <title>Resource Hub | Little Caesars Fundraising</title>
        </Helmet>
      </Flex>
      <Box data-testid="resource-hub-page" sx={ { flex: 1 } }>
        <FundraiserNavigation fundId={ match.params.fundraiserId } url={ match.url } />
        {children({
          resourceHubData, fundraiser, sellerSupportGuid, userIsChairperson, isChairpersonResourceHub,
        })}
      </Box>
    </>
  );
};

