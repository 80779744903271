import React from 'react';
import { Box } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import { ResourceHubHeader } from './ResourceHubHeader';
import { ResourceHubEmailSection } from './ResourceHubEmailSection';
import { ResourceHubSocialSection } from './ResourceHubSocialSection';
import { GoBackLink } from './components';

import { FundraiserListItem, FundraiserType } from 'features/fundraiser';

export interface IResourceHubProps{
  fundraiserRole: string;
  data: string;
  fundraiser: FundraiserListItem | undefined;
  sellerSupportGuid: string | undefined;
}

const ResourceHub: React.FC<IResourceHubProps> = ({
  fundraiserRole, data, fundraiser, sellerSupportGuid,
}) => {
  const isBrochure = fundraiser?.FundraiserType === FundraiserType.Brochure;
  const [ t ] = useTranslation();
  if (!fundraiser || !data) {
    return null;
  }

  return (
    <Box sx={ { mb: '16px' } }>
      {isBrochure && (
        <GoBackLink>
          {t('ResourceHub.BrochureHub.GoBackText')}
        </GoBackLink>
      )}
      <ResourceHubHeader heading={ data[fundraiserRole].heading } />
      <ResourceHubEmailSection
        emailSectionContent={ data[fundraiserRole].emailSection }
        fundraiser={ fundraiser }
        fundraiserRole={ fundraiserRole }
      />
      {!isBrochure && (
        <ResourceHubSocialSection
          fundraiser={ fundraiser }
          fundraiserRole={ fundraiserRole }
          sellerSupportGuid={ sellerSupportGuid }
          socialSectionContent={ data[fundraiserRole].socialSection }
        />
      )}
      {isBrochure && (
        <GoBackLink>
          {t('ResourceHub.BrochureHub.GoBackText')}
        </GoBackLink>
      )}
    </Box>
  );
};

export default ResourceHub;
