import React from 'react';
import {
  Box, Flex, Link, Text,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';

import { IFeaturedProductCard } from '../types';

import { FeaturedProductCard } from './FeaturedProductCard';

import { useJSONFeature } from 'ui/components/hooks';

const FeaturedProducts: React.FC = () => {
  const [ t ] = useTranslation();
  const featuredProducts = useJSONFeature<IFeaturedProductCard[]>('fr_featured_products').value;

  if (_isEmpty(featuredProducts)) {
    return null;
  }
  return (
    <Flex
      data-testid="featured-products"
      sx={ {
        alignItems: 'center',
        backgroundColor: 'rgba(247, 242, 236, 0.4)',
        flexDirection: 'column',
        gap: '16px',
        p: [ '32px 16px', '32px' ],
        textAlign: 'center',
        width: '100%',
      } }
    >
      <Text variant="home.subheading">
        {t('home.featuredProducts.title')}
      </Text>
      <Box
        sx={ {
          display: 'grid',
          gap: [ '16px', '16px', '32px' ],
          gridTemplateAreas: [
            '"product0" "product1" "product2"',
            '"product0 product0 product1 product1" ". product2 product2 ."',
            '"product0 product1 product2"',
          ],
          gridTemplateColumns: [ '1fr', '1fr 1fr 1fr 1fr', '1fr 1fr 1fr' ],
          maxWidth: '1216px',
          mx: 'auto',
        } }
      >
        {_map(featuredProducts, (product, index) => (
          <FeaturedProductCard { ...product } gridArea={ `product${ index }` } key={ product.name } />
        ))}
      </Box>
      <Link
        href={ t('home.featuredProducts.link.url') }
        sx={ { color: 'black', borderColor: 'black' } }
        variant="primaryOutlineButtonLink"
      >
        { t('home.featuredProducts.link.text') }
      </Link>
    </Flex>
  );
};

export default FeaturedProducts;
