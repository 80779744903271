import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from '@lce/i18n';
import {
  Button, Box, Flex, Form, Text, Spinner,
} from '@lce/slice_v2';

import { useTermsAndConditionsInput } from 'ui/components/forms';
import { Mode, Step } from 'ui/common/Step';
import { FundraiserExtraInfo } from 'features/fundraiser/types/fundraiser';
import HowDidYouHearAboutUsOptions from 'ui/common/HowDidYouHearAboutUsOptions';

export interface IAdditionalDetailsProps {
  isLoading: boolean;
  mode: Mode;
  onNext: (formData: FundraiserExtraInfo) => void;
  showHowDidYouHearAboutUs: boolean;
}

// eslint-disable-next-line max-lines-per-function
const AdditionalDetails: React.FC<IAdditionalDetailsProps> = ({
  onNext, isLoading, mode, showHowDidYouHearAboutUs,
}) => {
  const [ t ] = useTranslation();
  const { TermsAndConditionsInput, termsAndConditionsInputSchema } = useTermsAndConditionsInput();

  const handleOnSubmit = (e) => {
    const data: FundraiserExtraInfo = {
      ...e,
    };

    onNext(data);
  };
  const validationSchema = Yup.object().shape({
    Referral: Yup.string(),
    ...termsAndConditionsInputSchema,
  });

  return (
    <>
      { mode === 'active' && (
        <Step title={ t('fundraiser.AdditionalDetails') }>
          <Box variant="cards.step.body">
            <Form
              onSubmit={ handleOnSubmit }
              summary={ false }
              validationSchema={ validationSchema }
            >
              <Flex sx={ { flexDirection: 'column' } }>
                {showHowDidYouHearAboutUs && (
                  <>
                    <Text variant="text.header.form">
                      {t('fundraiser.Referral')}
                    </Text>
                    <Form.Field
                      component={ Form.Select }
                      defaultValue=""
                      id="Referral"
                      label="Select from the dropdown list"
                      name="Referral"
                      sx={ { mr: '12px', width: 'fit-content' } }
                    >
                      <HowDidYouHearAboutUsOptions />
                    </Form.Field>
                  </>
                )}
                <Text variant="text.header.form">
                  {t('fundraiser.TermsAndConditions')}
                </Text>
                <TermsAndConditionsInput />
                <Button
                  disabled={ isLoading }
                  id="chairpersonNext"
                  sx={ { mt: '33px' } }
                  type="submit"
                  variant={ isLoading ? 'disabled' : 'primary' }
                >
                  { isLoading ? (
                    <Spinner sx={ { height: '100%' } } variant="lce" />
                  ) : t('fundraiser.CreateFundraiser') }
                </Button>
              </Flex>
            </Form>
          </Box>
        </Step>
      )}

      { mode === 'hidden' && (
        <Step title={ t('fundraiser.AdditionalDetails') } />
      )}
    </>
  );
};

export default AdditionalDetails;
