import React, { useState } from 'react';
import { RefetchOptions, RefetchQueryFilters, QueryObserverResult } from 'react-query';

import { RefetchDataDisplay } from '../../components/data';

import { ApiError } from 'features/common';

export interface IUseFetchParams {
  refetch: (options?: (RefetchOptions & RefetchQueryFilters)) =>
  Promise<QueryObserverResult<any, ApiError>>;
  maxRetries?: number;
}

export const maxRetriesDefault = 3;

export const useRefetch = ({ refetch, maxRetries = maxRetriesDefault }: IUseFetchParams): React.FC => {
  const [ totalRefetchCount, setTotalRefetchCount ] = useState(0);

  // eslint-disable-next-line react/display-name
  return () => (
    <RefetchDataDisplay
      maxRetries={ maxRetries }
      refetch={ refetch }
      setTotalRefetchCount={ setTotalRefetchCount }
      totalRefetchCount={ totalRefetchCount }
    />
  );
};
