/* eslint-disable no-confusing-arrow */
/* eslint-disable max-lines-per-function */
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ResourceHubPage as ResourceHubWrapper } from 'ui/fundraiser/ResourceHub/ResourceHubPage';
import { ResourceHub } from 'ui/fundraiser/ResourceHub';
import { MatchParams } from 'ui/fundraiser/ResourceHub/hooks';

type ResourceHubPageProps = RouteComponentProps<MatchParams>

const ResourceHubPage: React.FC<ResourceHubPageProps> = props => (
  <ResourceHubWrapper { ...props }>
    {({
      resourceHubData, fundraiser, sellerSupportGuid, userIsChairperson, isChairpersonResourceHub,
    }) => userIsChairperson && isChairpersonResourceHub
      ? (
        <ResourceHub
          data={ resourceHubData }
          fundraiser={ fundraiser }
          fundraiserRole="chairperson"
          sellerSupportGuid={ sellerSupportGuid }
        />
      )
      : (
        <ResourceHub
          data={ resourceHubData }
          fundraiser={ fundraiser }
          fundraiserRole="seller"
          sellerSupportGuid={ sellerSupportGuid }
        />
      )}

  </ResourceHubWrapper>
);

export default ResourceHubPage;
