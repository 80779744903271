/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import {
  Box, Text, Flex, Button,
} from '@lce/slice_v2';
import React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';
import { useTranslation } from '@lce/i18n';

import * as FundraiserDefaults from 'constants/FundraiserDefaults';
import { ScriptParser } from 'ui/common/ScriptParser';
import { UserIcon } from 'ui/userLanding/UserIcon';
import { InlineLinkParseAndReplace } from 'ui/components';

export interface IUserOptionProps {
  sx?: ThemeUIStyleObject;
  role: string;
  side: string;
}

interface IText {
  buttonText?: string;
  description: string;
  link?: string;
  script?: string;
  title: string;
}

const UserOption: React.FC<IUserOptionProps> = ({ role, side }) => {
  const [ t ] = useTranslation();
  const text: IText = t(`userLandingPages.${ role }.${ side }`, { returnObjects: true });

  const isChairperson = role === FundraiserDefaults.ChairpersonRoleName.toLowerCase();
  const link = FundraiserDefaults.BuyerRoleName.toLowerCase() === role && side !== 'left' ? process.env.REACT_APP_ONGOING_FUNDRAISER_URL : text.link;

  return (
    <Flex
      data-testid={ `fundraiser-${ role }-option-icon-${ side }` }
      sx={ {
        flexDirection: 'column', alignItems: 'center',
        width: role === FundraiserDefaults.BuyerRoleName.toLowerCase()
          ? [ '100%', '100%', '548px' ]
          : [ '100%', '100%', '488px' ],
        m: side === 'left' ? [ 0, 0, '0 80px 0 0' ] : isChairperson && side === 'right' ? [ 0, 0, '14px 0 0 80px' ] : [ 0, 0, '0 0 0 80px' ],
      } }
    >

      <Box
        data-testid={ `${ role }-option-icon` }
        sx={ {
          display: 'inline-block', textAlign: 'center',
        } }
      >
        <UserIcon isLeft={ side === 'left' } role={ role } />
      </Box>

      <Box
        sx={ {
          m: role === FundraiserDefaults.BuyerRoleName.toLowerCase()
            ? [ '20px 24px 0', '24px 28px 0', '24px 0 0' ]
            : '24px 0 0',
          textAlign: 'center', lineHeight: 1,
        } }
      >
        <Text
          as="span"
          sx={ {
            display: 'inline-block',
            fontFamily: 'lato',
            fontWeight: '900',
            color: 'primaryOrange',
            fontSize: [ '24px', '24px', '25px' ],
          } }
        >
          <ScriptParser
            scriptText={ text.script }
            text={ text.title }
          />
        </Text>
      </Box>
      <Text
        sx={ {
          fontWeight: '500', fontFamily: 'roboto', fontSize: [ '18px' ],
          textAlign: 'center',
          p: [ '0 13px', '0 44px', 0 ], my: [ 4, 4, 6 ],
        } }
      >
        <InlineLinkParseAndReplace text={ text.description } wordsToLink={ [] } />
      </Text>

      {text.buttonText && link && (
        <Button
          data-testid={ `${ text.buttonText }-option-button` }
          onClick={ () => window.location.assign(`${ link }`) }
          sx={ {
            width: '229px', height: [ '38px' ],
            m: [ '28px 0 28px 0', '28px 0 28px 0', '28px 0 100px 0' ],
          } } variant="primary"
        >
          { text.buttonText }
        </Button>
      )}

    </Flex>
  );
};

export default UserOption;
