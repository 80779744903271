/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import {
  PrivateRoute,
} from '../../../ui/common';

import { Seo, ISeoProps } from 'ui/common/Seo';

type PrivateRouterWrapperProps = React.PropsWithChildren<{
  component: React.ComponentType<any>;
  layout: React.ComponentType<any>;
  roles: any;
  seo?: ISeoProps;
  redirectPath?: string;
}> & Omit<React.ComponentProps<typeof PrivateRoute>, 'component'>;

export const PrivateRouteWrapper = ({
  component: Component,
  layout: Layout,
  roles: Roles,
  seo,
  redirectPath = '/',
  ...rest
}: PrivateRouterWrapperProps) => (
  <>
    <Seo { ...seo } />
    <Layout>
      <PrivateRoute
        component={ Component }
        redirectPath={ redirectPath }
        roles={ Roles }
        { ...rest }
      />
    </Layout>
  </>
);
