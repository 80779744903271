/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { GenericToast } from '../Toast/CustomToasts';

export interface IFileUploadProps {
  onFileSelect: (e: File) => void;
}

interface Event<T = EventTarget> {
  target: T;
}

const FileUpload: React.FC<IFileUploadProps> = ({ onFileSelect }) => {
  const [ file, setFile ] = useState<File>();
  const [ fileName, setFileName ] = useState<string>('');

  const saveFile = (e: Event<HTMLInputElement>) => {
    if (e.target && e.target.files && e.target.files.length > 0) {
      const type = e.target.files[0].type;
      switch (type) {
        case 'image/gif':
        case 'image/jpeg':
        case 'image/png':
        case 'image/jpg':
          setFile(e.target.files[0]);
          setFileName(e.target.files[0].name);
          onFileSelect(e.target.files[0]);
          break;
        default:
          toast(<GenericToast text="Supported file types are .jpg, .jpeg, .png, and .gif" />);
          break;
      }
    }
  };

  return (
    <>
      <label
        data-testid="Change Photo"
        htmlFor="file-upload" style={ {
          fontSize: '14px', fontWeight: 'bold', color: '#FF671B', cursor: 'pointer', textAlign: 'center',
        } }
      >
        Change Photo
      </label>
      <input
        accept="image/gif, image/jpeg, image/png, image/jpg"
        data-testid="file-upload"
        id="file-upload"
        onChange={ saveFile }
        style={ { display: 'none' } }
        type="file"
      />
    </>
  );
};

export default FileUpload;
