/* eslint-disable max-len */
import React from 'react';
import { find as _find, map as _map } from 'lodash';
import { Box } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import DashboardStep from '../DashboardStep';

import {
  CompleteYourProfile,
  FundraiserSupporters,
  InviteSellers,
  ManageYourSellers,
  PlaceFinalOrder,
  PromoteYourFundraiser,
  ReceiveYourProfitCheck,
} from './Components/Steps';

import { FundraiserType, useFetchSellerByFundraiserId } from 'features/fundraiser';
import { IStep, IStepFundraiserProp, PopoverType } from 'features/fundraiser/types/dashboard';
import { useBooleanFeature } from 'ui/components/hooks';

// eslint-disable-next-line max-lines-per-function
const ChairpersonSteps: React.FC<IStepFundraiserProp> = ({ fundraiser, handleAddressModal, isMissingProfitCheckAddress }) => {
  const [ t ] = useTranslation();
  const { data: sellers } = useFetchSellerByFundraiserId(fundraiser.Id.toString());
  const isFundraiserExpiredOrCancelled = fundraiser.CancellationDate || fundraiser.IsExpired;

  const sellerSupportGuid = _find(sellers,
    seller => seller.CustomerId === fundraiser.ChairpersonCustomerId)?.SupportGuid;

  const sellerId = _find(sellers,
    seller => seller.CustomerId === fundraiser.ChairpersonCustomerId)?.Id;

  const hasSellerSupportGuidAndId = Boolean(sellerSupportGuid && sellerId);

  const chairpersonStepsByFundraiserType: Record<string, IStep[]> = {
    DG: [
      {
        stepNumber: 1,
        hasInfo: false,
        title: t('dashboard.steps.chairperson.step1.title'),
        content: <CompleteYourProfile />,
      },
      {
        stepNumber: 2,
        title: t('dashboard.steps.chairperson.step2.title'),
        hasInfo: true,
        popupType: PopoverType.Seller,
        content: <InviteSellers fundraiser={ fundraiser } idSuffix={ `-2-${ fundraiser.FundraiserType }` } />,
      },
      {
        stepNumber: 3,
        title: t('dashboard.steps.chairperson.step3.title'),
        hasInfo: true,
        popupType: PopoverType.Manage,
        content: <ManageYourSellers fundraiser={ fundraiser } />,
      },
      {
        stepNumber: 4,
        title: hasSellerSupportGuidAndId ? t('dashboard.steps.chairperson.step4.title') : t('dashboard.steps.chairperson.step4.joinTitle'),
        hasInfo: hasSellerSupportGuidAndId,
        popupType: PopoverType.Buyer,
        content: <FundraiserSupporters fundraiser={ fundraiser } idSuffix={ `-4-${ fundraiser.FundraiserType }` } sellerId={ sellerId } sellerSupportGuid={ sellerSupportGuid } />,
      },
      {
        stepNumber: 5,
        title: t('dashboard.steps.chairperson.promoteYourFundraiser.title'),
        hasInfo: false,
        popupType: PopoverType.Buyer,
        content: <PromoteYourFundraiser fundraiser={ fundraiser } />,
      },
    ],
    SS: [
      {
        stepNumber: 1,
        hasInfo: false,
        title: t('dashboard.steps.chairperson.step1.title'),
        content: <CompleteYourProfile />,
      },
      {
        stepNumber: 2,
        title: t('dashboard.steps.chairperson.step2.title'),
        hasInfo: true,
        popupType: PopoverType.Seller,
        content: <InviteSellers fundraiser={ fundraiser } idSuffix={ `-2-${ fundraiser.FundraiserType }` } />,
      },
      {
        stepNumber: 3,
        title: t('dashboard.steps.chairperson.step3.title'),
        hasInfo: true,
        popupType: PopoverType.Manage,
        content: <ManageYourSellers fundraiser={ fundraiser } />,
      },
      {
        stepNumber: 4,
        title: hasSellerSupportGuidAndId ? t('dashboard.steps.chairperson.step4.title') : t('dashboard.steps.chairperson.step4.joinTitle'),
        hasInfo: hasSellerSupportGuidAndId,
        popupType: PopoverType.Buyer,
        content: <FundraiserSupporters fundraiser={ fundraiser } idSuffix={ `-4-${ fundraiser.FundraiserType }` } sellerId={ sellerId } sellerSupportGuid={ sellerSupportGuid } />,
      },
      {
        stepNumber: 5,
        title: t('dashboard.steps.chairperson.promoteYourFundraiser.title'),
        hasInfo: false,
        popupType: PopoverType.Buyer,
        content: <PromoteYourFundraiser fundraiser={ fundraiser } />,
      },
    ],
    MD: [
      {
        stepNumber: 1,
        hasInfo: false,
        title: t('dashboard.steps.chairperson.step1.title'),
        content: <CompleteYourProfile />,
      },
      {
        stepNumber: 2,
        title: t('dashboard.steps.chairperson.step2.title'),
        hasInfo: true,
        popupType: PopoverType.Seller,
        content: <InviteSellers fundraiser={ fundraiser } idSuffix={ `-2-${ fundraiser.FundraiserType }` } />,
      },
      {
        stepNumber: 3,
        title: t('dashboard.steps.chairperson.step3.title'),
        hasInfo: true,
        popupType: PopoverType.Manage,
        content: <ManageYourSellers fundraiser={ fundraiser } />,
      },
      {
        stepNumber: 4,
        title: hasSellerSupportGuidAndId ? t('dashboard.steps.chairperson.step4.title') : t('dashboard.steps.chairperson.step4.joinTitle'),
        hasInfo: hasSellerSupportGuidAndId,
        popupType: PopoverType.Buyer,
        content: <FundraiserSupporters fundraiser={ fundraiser } idSuffix={ `-4-${ fundraiser.FundraiserType }` } sellerId={ sellerId } sellerSupportGuid={ sellerSupportGuid } />,
      },
      {
        stepNumber: 5,
        title: t('dashboard.steps.chairperson.receiveYourProfitCheck.title'),
        hasInfo: false,
        popupType: PopoverType.Buyer,
        content: <ReceiveYourProfitCheck handleAddressModal={ handleAddressModal } isExpired={ isFundraiserExpiredOrCancelled } isMissingProfitCheckAddress={ isMissingProfitCheckAddress } />,
        borderStyle: isFundraiserExpiredOrCancelled && isMissingProfitCheckAddress ? 'solid 3px #ef0000' : undefined,
      },
      {
        stepNumber: 6,
        title: t('dashboard.steps.chairperson.promoteYourFundraiser.title'),
        hasInfo: false,
        popupType: PopoverType.Buyer,
        content: <PromoteYourFundraiser fundraiser={ fundraiser } />,
      },
    ],
    BR: [
      {
        stepNumber: 1,
        hasInfo: false,
        title: t('dashboard.steps.chairperson.step1.title'),
        content: <CompleteYourProfile />,
      },
      {
        stepNumber: 2,
        title: t('dashboard.steps.chairperson.step2.title'),
        hasInfo: true,
        popupType: PopoverType.Seller,
        content: <InviteSellers fundraiser={ fundraiser } idSuffix={ `-2-${ fundraiser.FundraiserType }` } />,
      },
      {
        stepNumber: 3,
        title: t('dashboard.steps.chairperson.step3.title'),
        hasInfo: true,
        popupType: PopoverType.Manage,
        content: <ManageYourSellers fundraiser={ fundraiser } />,
      },
      {
        stepNumber: 4,
        title: hasSellerSupportGuidAndId ? t('dashboard.steps.chairperson.step4.title') : t('dashboard.steps.chairperson.step4.joinTitle'),
        hasInfo: hasSellerSupportGuidAndId,
        popupType: PopoverType.Buyer,
        content: <FundraiserSupporters fundraiser={ fundraiser } idSuffix={ `-4-${ fundraiser.FundraiserType }` } sellerId={ sellerId } sellerSupportGuid={ sellerSupportGuid } />,
      },
      {
        stepNumber: 5,
        title: t('dashboard.steps.chairperson.promoteYourFundraiser.title'),
        hasInfo: false,
        popupType: PopoverType.Buyer,
        content: <PromoteYourFundraiser fundraiser={ fundraiser } />,
      },
      {
        stepNumber: 6,
        title: t('dashboard.steps.chairperson.placeFinalOrder.title'),
        hasInfo: false,
        popupType: PopoverType.Buyer,
        content: <PlaceFinalOrder fundraiser={ fundraiser } />,
      },
    ],
  };

  const displayBrochureSteps = useBooleanFeature('fr_brochure_program_enabled').on;
  const isBrochureSteps = fundraiser.FundraiserType === FundraiserType.Brochure;
  if (!displayBrochureSteps && isBrochureSteps) {
    return null;
  }
  return (
    <Box
      data-testid="chairperson-steps"
      sx={ {
        display: [ 'flex', 'flex', 'flex', 'grid' ],
        flexWrap: 'wrap',
        justifyContent: 'center',
        gridTemplateRows: 'auto auto auto',
        gridTemplateColumns: 'auto auto',
        gap: 4,
      } }
    >
      {_map(chairpersonStepsByFundraiserType[fundraiser.FundraiserType], (step, index) => (
        <DashboardStep
          borderStyle={ step.borderStyle } hasInfo={ step.hasInfo } key={ index }
          popupType={ step.popupType } stepNumber={ step.stepNumber } title={ step.title }
        >
          { step.content}
        </DashboardStep>
      ))}
    </Box>
  );
};

export default ChairpersonSteps;
