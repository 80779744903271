/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { CancelToken, AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { GrowthBook } from '@growthbook/growthbook-react';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import { ApiError } from './features/common/types/response';
import { getStoredAuthToken } from './auth/authToken';

/**
 * Retrieve the Okta access token from local storage
 * We should _probably_ do this another way, but this works for now
 */
const getAuthToken = (existingAuthToken) => {
  // if not provided, grab it manually from local storage
  if (existingAuthToken) {
    return existingAuthToken;
  }

  const authStorage = getStoredAuthToken();
  if (!authStorage) {
    return undefined;
  }

  const data = JSON.parse(authStorage);
  return data.accessToken?.accessToken;
};

const growthbook = new GrowthBook({
  apiHost: process.env.REACT_APP_GROWTHBOOK_API_HOST,
  clientKey: process.env.REACT_APP_GROWTHBOOK_SDK_KEY,
  enableDevMode: true,
  disableCache: true,
});

growthbook.init({ streaming: false });

const defaults = {
  baseURL: process.env.REACT_APP_BASE_API_URL || 'https://localhost:44379',
  headers: authToken => ({
    'Content-Type': 'application/json',
    Authorization: getAuthToken(authToken) ? `Bearer ${ getAuthToken(authToken) }` : undefined,
  }),
};

type PathsToMock = {
  method: string;
  path: string;
  branch?: string;
  example?: string;
  httpStatusCode?: number;
}

enum Presets {
  FINAL_ORDER_DUE = 'finalOrderDue',
  FINAL_ORDER_COMPLETE = 'finalOrderComplete'
}

type mockApiConfig = {
  mockURL: string;
  clientId: string;
  enabled: boolean;
  preset: Presets;
}

axios.interceptors.request.use((config) => {
  const {
    mockURL, clientId, enabled = false, preset,
  }: mockApiConfig = growthbook.evalFeature('fr_mock_api_config').value ?? {};
  const presets = growthbook.evalFeature('fr_mock_api_presets').value ?? {};
  const endpoints = growthbook.evalFeature('fr_mock_api_endpoints').value ?? [];
  const endpointNames = presets[preset];

  if (!enabled || _isEmpty(presets) || _isEmpty(endpoints) || _isNil(endpointNames)) {
    return config;
  }

  const mockedPaths: PathsToMock[] = endpointNames.map((name: string) => endpoints[name]);
  const matchingPath = mockedPaths.find(({ method, path }) => method === config.method && config.url?.match(new RegExp(`${ defaults.baseURL }/${ path }$`)));

  if (matchingPath) {
    const { branch = '', example, httpStatusCode = 200 } = matchingPath;
    const mockConfigUrl = `${ mockURL }${ branch }/${ clientId }`;
    config.url = config.url?.replace(defaults.baseURL, mockConfigUrl);

    if (example) {
      config.headers['Prefer'] = `code=${ httpStatusCode }, example=${ example }`;
    }
  }

  return config;
}, error => Promise.reject(error));

const applyErrorMessages = (reject: (reason?: any) => void) => (error: AxiosError, status: number) => {
  const err: ApiError = {
    Status: status,
    Message: error.response?.data.Message ?? error.response?.data,
    ErrorCode: error.response?.data.ErrorCode,
  };
  reject(err);
};

function api<T = any>(method: any, path: string, params?: any, authToken?: string, cancelToken?: CancelToken):
Promise<T> {
  return new Promise((resolve, reject) => {
    axios({
      url: `${ defaults.baseURL }/${ path }`,
      method: method,
      headers: defaults.headers(authToken),
      params: method === 'get' || method === 'GET' ? params : undefined,
      data: method !== 'get' && method !== 'GET' ? params : undefined,
      cancelToken: cancelToken,
    }).then((response) => {
      resolve(response.data);
    }, (error: AxiosError) => {
      const rejectWithError = applyErrorMessages(reject);
      console.log(error);
      if (axios.isCancel(error)) {
        reject('CANCEL');
      } else if (error.message === 'Network Error') {
        reject(error);
      } else if (error.response && error.response.status === 401) {
        // Unauthenticated
        reject(error.response.data as ApiError);
        toast.error('You must be authenticated');
      } else if (error.response && error.response.status === 403) {
        // Unauthorized
        rejectWithError(error, 403);
      } else if (error.response && error.response.status === 404) {
        rejectWithError(error, 404);
      } else if (error.response) {
        rejectWithError(error, error.response.status);
      }
    });
  });
}

function downloadApi<T = any>(method: any, path: string, params?: any, authToken?: string): Promise<T> {
  return new Promise((resolve) => {
    axios({
      url: `${ defaults.baseURL }/${ path }`,
      method: 'GET',
      headers: defaults.headers(authToken),
      params: params,
      responseType: 'blob',
    }).then((response) => {
      const fileName = response.request.getResponseHeader('x-suggested-filename');
      const url = window.URL.createObjectURL(new Blob([ response.data ]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName || 'report.xlsx');
      document.body.appendChild(link);
      link.click();
      resolve(response.data);
    });
  });
}

export default {
  get: <T = any>(path, params?, accessToken?, cancelToken?: CancelToken) => api<T>('get', path, params, accessToken, cancelToken),
  post: <T = any>(path, params?, accessToken?) => api<T>('post', path, params, accessToken),
  put: <T = any>(path, params?, accessToken?) => api<T>('put', path, params, accessToken),
  patch: <T = any>(path, params?, accessToken?) => api<T>('patch', path, params, accessToken),
  delete: <T = any>(path, params?, accessToken?) => api<T>('delete', path, params, accessToken),
  download: <T = any>(path, params?, accessToken?) => downloadApi<T>('get', path, params, accessToken),
};
