import numeral from 'numeral';
import _find from 'lodash/find';
import { useTranslation } from '@lce/i18n';
import { RouteComponentProps } from 'react-router-dom';

import { formatFundraiserDateRange, htmlDecode } from '../utils';

import { useFetchProductsPageRoute } from 'features/products';
import {
  useIsUserElevatedRelationship,
  useFetchChairpersonDashboardStatistics,
  useFetchSeller,
  useFetchSellerByFundraiserId,
} from 'features/fundraiser';
import { FundraiserListItem } from 'features/fundraiser/types';

export interface MatchParams {
  fundraiserId: string;
  customerId?: string;
}

type UseResourceHubParams = RouteComponentProps<MatchParams> & {
  fundraiser: FundraiserListItem | undefined;
}

export type ResourceHubResponse = {
  userIsChairperson: boolean;
  isChairpersonResourceHub: boolean;
  resourceHubData: string;
  sellerSupportGuid: string | undefined;
}

export const useResourceHub = ({ match, fundraiser }: UseResourceHubParams): ResourceHubResponse => {
  const [ t ] = useTranslation();
  const fundraiserId = parseInt(match.params.fundraiserId) || 0;
  const customerId = match.params.customerId ? parseInt(match.params.customerId) : 0;
  const { data: sellers } = useFetchSellerByFundraiserId(fundraiser?.Id.toString() || '');
  const isChairpersonResourceHub = match.url.endsWith(`/fundraisers/${ fundraiserId }/resource-hub`) ||
  match.url.endsWith(`/fundraisers/${ fundraiserId }/resource-hub/brochure`);
  const { data: seller } = useFetchSeller(fundraiserId, customerId);
  const { data: chairpersonStats } = useFetchChairpersonDashboardStatistics(fundraiser?.Id || 0);

  const joinFundraiserLink =
    htmlDecode(`${ window.location.origin }/login/join-fundraiser/${ fundraiser?.FundraiserGuid }`);

  const totalKitsSold = _find(chairpersonStats, stat => stat.Key === 'TotalKitsSoldByFundraiser')?.Value;
  const fundraiserProfit = _find(chairpersonStats, stat => stat.Key === 'TotalProfitRaisedByFundraiser')?.Value;
  const formattedProfit = numeral(fundraiserProfit).format(t('currency.dollarGoal'));
  const sellerSupportGuid = isChairpersonResourceHub
    ? _find(sellers,
      seller => seller.CustomerId === fundraiser?.ChairpersonCustomerId)?.SupportGuid
    : seller?.SupportGuid;

  const productPageRoute = useFetchProductsPageRoute(sellerSupportGuid, fundraiser?.FundraiserType);
  const buyerLink = sellerSupportGuid
    ? `${ window.location.origin }${ productPageRoute }`
    : `${ window.location.origin }/login/join-fundraiser/${ fundraiser?.FundraiserGuid }`;

  const dateRange = formatFundraiserDateRange(fundraiser?.StartDate, fundraiser?.EndDate);

  const userIsChairperson = useIsUserElevatedRelationship(fundraiser);

  const fundraiserDescription = fundraiser?.CustomMessage ? htmlDecode(`[${ fundraiser?.CustomMessage }]. `) : '';
  const totalRaised = fundraiser?.TotalRaised;
  const resourceHubData =
    t(`ResourceHub.${ fundraiser?.FundraiserType }`, {
      dateRange,
      fundraiserDescription,
      joinFundraiserLink,
      totalKitsSold,
      formattedProfit,
      buyerLink,
      fundraiserName: fundraiser?.Name,
      totalRaised,
      returnObjects: true,
    });

  return {
    userIsChairperson, isChairpersonResourceHub, resourceHubData, sellerSupportGuid,
  };
};
