import React, { useReducer } from 'react';

import { checkoutReducer } from './checkoutReducer';
import { CheckoutDispatchContext, CheckoutStateContext } from './checkoutContext';

type ICheckoutProviderProps = {
  children: React.ReactNode;
}

export const CheckoutProvider = ({ children }: ICheckoutProviderProps) => {
  const [ checkout, dispatch ] = useReducer(checkoutReducer, {});

  return (
    <CheckoutStateContext.Provider value={ checkout }>
      <CheckoutDispatchContext.Provider value={ dispatch }>
        {children}
      </CheckoutDispatchContext.Provider>
    </CheckoutStateContext.Provider>
  );
};
