/* eslint-disable react/forbid-component-props */
import {
  Card, Flex, Text,
} from '@lce/slice_v2';
import React from 'react';
import { useHistory } from 'react-router-dom';

export interface IResourceIconCard {
  children: React.ReactNode;
  pathName: string;
  title: string;
  mt?: string;
}

export const ResourceIconCard: React.FC<IResourceIconCard> = ({
  children, pathName, title, mt,
}) => {
  const history = useHistory();
  return (
    <Card
      data-testid={ `${ title }-resource-icon-card` }
      onClick={ () => history.push(pathName) }
      sx={ {
        height: '150px',
        width: '150px',
        p: '16px',
        cursor: 'pointer',
        '&:hover': { outline: '1px solid #FF6000', borderColor: 'primaryOrange' },
      } }
    >
      <Flex
        sx={ {
          justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%', mt: mt ? mt : 0,
        } }
      >
        {children}
        <Text sx={ { textAlign: 'center' } } variant="header.subUpper">
          {title}
        </Text>
      </Flex>
    </Card>
  );
};
