import React, { useState, useEffect, ReactElement } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useTranslation } from '@lce/i18n';
import {
  Flex, Box, Spinner, Container,
} from '@lce/slice_v2';
import { Helmet } from 'react-helmet-async';

import { ICheckoutWrapperChildrenProps } from 'ui/checkout/Types/ICheckoutWrapperChildrenProps';
import { Login } from 'ui/checkout/Login';
import { CheckoutProcess } from 'ui/checkout/CheckoutProcess';
import { CheckoutOrderSummary } from 'ui/checkout/OrderSummary';
import { CheckoutProvider, PaymentProvider } from 'features/checkout';
import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';

export interface ICheckoutWrapperProps {
  children: (props: ICheckoutWrapperChildrenProps) => ReactElement;
  LoginComponent?: React.FC;
  shouldDisplayShipping?: boolean;
  shouldDisplaySubtotal?: boolean;
}

// eslint-disable-next-line max-lines-per-function
export const CheckoutWrapper: React.FC<ICheckoutWrapperProps> = ({
  children,
  LoginComponent = Login,
  shouldDisplayShipping = true,
  shouldDisplaySubtotal = true,
}) => {
  const [ t ] = useTranslation();
  const { fundraiserType } = useFundraiserState();

  const [ guest, setGuest ] = useState<boolean>(false);
  const [ auth, setAuth ] = useState<boolean>(false);
  const [ loading, setLoading ] = useState<boolean>(true);

  const { authState } = useOktaAuth();

  const onGuestCallback = () => {
    setGuest(true);
  };

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      setAuth(true);
      setGuest(false);
      setLoading(false);
    } else if (authState) {
      setLoading(false);
    }
  }, [ authState ]);

  const LoginBody = <LoginComponent onGuestCallback={ onGuestCallback } />;

  const CheckoutBody = (
    <div data-testid="checkout-wrapper-body">
      <Helmet>
        <script src={ process.env.REACT_APP_EPROTECT_URL + '/eProtect/eProtect-api3.js' } type="text/javascript" />
      </Helmet>
      <CheckoutProvider>
        <PaymentProvider>
          <h1>
            {t('checkout.Header')}
          </h1>
          <Flex sx={ { flexDirection: [ 'column', 'column', 'row' ] } }>
            <CheckoutProcess isGuest={ guest }>
              {children}
            </CheckoutProcess>

            <Box sx={ { mt: '24px' } }>
              <CheckoutOrderSummary
                fundraiserType={ fundraiserType }
                shouldDisplayShipping={ shouldDisplayShipping }
                shouldDisplaySubtotal={ shouldDisplaySubtotal }
              />
            </Box>
          </Flex>
        </PaymentProvider>
      </CheckoutProvider>
    </div>
  );

  if (loading) {
    return (
      <Container sx={ { textAlign: 'center' } }>
        <Spinner variant="lce" />
      </Container>
    );
  }

  return !auth && !guest ? LoginBody : CheckoutBody;
};
