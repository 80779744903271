import { useCallback } from 'react';

import { useFundraiserDispatch } from 'features/fundraiser/context/fundraiserContext';
import { Seller } from 'features/fundraiser/types';
import {
  useFetchSellerBySupportGuid,
} from 'features/fundraiser/hooks/useFetchSellerBySupportGuid';

export const useStatefulFetchSellerBySupportGuid = () => {
  const fundraiserDispatch = useFundraiserDispatch();

  const updateFundraiserState = useCallback(({
    FundraiserId: fundraiserId,
    FundraiserType: fundraiserType,
  }: Seller) => {
    fundraiserDispatch({ type: 'SET_FUNDRAISER_ID', fundraiserId });
    fundraiserDispatch({ type: 'SET_FUNDRAISER_TYPE', fundraiserType });
  }, [ fundraiserDispatch ]);

  return useFetchSellerBySupportGuid(updateFundraiserState);
};
