import {
  Box, Card, Flex, Link, Image, Text,
} from '@lce/slice_v2';
import React from 'react';

import { InlineLinkParseAndReplace } from 'ui/components';

export interface IPrintableCard {
  contentType: string;
  link: string;
  src: string;
  text: string;
  title: string;
}

export const PrintableCard = ({
  contentType, link, src, text, title,
}) => (
  <Card
    data-testid={ `${ title }-printable-card` }
    sx={ {
      p: '12px',
      borderRadius: '10px',
      border: 'solid 1px #c4c4c4',
      '&:hover': {
        borderColor: 'primaryOrange',
        outline: '1px solid #FF6000',
      },
    } }
  >
    <Link
      data-testid="printable-card-link"
      href={ contentType === 'pdf'
        ? link
        : `https://view.officeapps.live.com/op/view.aspx?src=${ link }` }
      rel="noopener noreferrer"
      sx={ {
        textDecoration: 'none',
        '&:hover': { color: 'black' },
      } }
      target="_blank"
    >
      <Flex sx={ { gap: '20px' } }>
        <Box sx={ { width: '90px', height: '120px' } }>
          <Image
            data-testid="printable-card-image"
            src={ src }
            sx={ { width: '100%', height: '100%', cursor: 'pointer' } }
          />
        </Box>
        <Box sx={ { maxWidth: [ '200px', '450px', '450px', '200px' ] } }>
          <Text sx={ { mb: '4px', fontSize: '24px', textTransform: 'none' } } variant="header.subUpper">
            {title}
          </Text>
          <Text sx={ { fontSize: '14px', fontFamily: 'roboto', fontWeight: 500 } }>
            <InlineLinkParseAndReplace text={ text } />
          </Text>
        </Box>
      </Flex>
    </Link>
  </Card>
);
