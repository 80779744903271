/* eslint-disable react/forbid-component-props */
/* eslint-disable max-lines-per-function */
import { useTranslation } from '@lce/i18n';
import {
  Box, Flex, Grid, Text,
} from '@lce/slice_v2';
import { map as _map } from 'lodash';
import React from 'react';
import { EmailOutlined, ImageSearch } from '@material-ui/icons';

import { PrintableCard } from '../PrintableCard';
import SampleBrochureImage from '../../assets/SampleBrochure.png';
import TallySheetImage from '../../assets/TallySheet.png';
import KickOffGuideImage from '../../assets/KickOffGuide.png';
import AnnouncementLetterImage from '../../assets/AnnouncementLetter.png';
import { ResourceIconCard } from '../ResourceIconCard';

import { Step } from 'ui/common';

const PrintableResourcesImagesObj = {
  'Sample Brochure': SampleBrochureImage,
  'Tally Sheet': TallySheetImage,
  'Announcement Letter': AnnouncementLetterImage,
  'Kickoff-Guide': KickOffGuideImage,
};

interface PrintableCardTranslation {
  title: string;
  text: string;
  link: string;
  contentType: string;
}

const BrochureResourceHub: React.FC = () => {
  const { t } = useTranslation();
  const cards: PrintableCardTranslation[] =
  t('ResourceHub.BrochureHub.PrintableResourcesCards', { returnObjects: true });

  return (
    <Box data-testid="brochure-resource-hub" sx={ { maxWidth: '750px', mb: [ '16px', '32px' ] } }>
      <Text sx={ { mb: '20px' } } variant="header.title">
        {t('ResourceHub.BrochureHub.Title')}
      </Text>
      <Text sx={ { mb: '40px' } } variant="header.form">
        {t('ResourceHub.BrochureHub.SubTitle')}
      </Text>
      <Step title={ t('ResourceHub.BrochureHub.PrintableResources') }>
        <Flex
          sx={ {
            p: [ '16px', '24px' ],
            flexDirection: 'column',
          } }
        >
          <Text sx={ { mb: '24px' } } variant="header.form">
            {t('ResourceHub.BrochureHub.PrintableResourcesText')}
          </Text>
          <Grid
            sx={ {
              gridTemplateRows: [ 'repeat(4, 1fr)', 'repeat(4, 1fr)', 'repeat(4, 1fr)', 'repeat(2, 1fr)' ],
              gridTemplateColumns: [ 'repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)' ],
            } }
          >
            {_map(cards, (card, index) => (
              <PrintableCard
                contentType={ card.contentType }
                key={ index }
                link={ card.link }
                src={ PrintableResourcesImagesObj[card.title] }
                text={ card.text }
                title={ card.title }
              />
            )) }
          </Grid>
        </Flex>
      </Step>

      <Step sx={ { mb: '12px' } } title={ t('ResourceHub.BrochureHub.DigitalResources') }>
        <Flex sx={ { p: '24px', flexDirection: 'column' } }>
          <Text sx={ { mb: '24px' } } variant="header.form">
            {t('ResourceHub.BrochureHub.DigitalResourcesText')}
          </Text>
          <Flex sx={ { gap: '12px' } }>
            <ResourceIconCard pathName="brochure/email-templates" title={ t('ResourceHub.BrochureHub.EmailTemplates') }>
              <Box sx={ { mb: '-8px' } }>
                <EmailOutlined style={ { width: 60, height: 50 } } />
              </Box>
            </ResourceIconCard>
            <ResourceIconCard
              mt="-10px"
              pathName="brochure/downloadable-graphics" title={ t('ResourceHub.BrochureHub.Graphics') }
            >
              <Box
                sx={ {
                  mb: '-4px',
                } }
              >
                <ImageSearch style={ { height: 48, width: 42 } } />
              </Box>
            </ResourceIconCard>
          </Flex>
        </Flex>
      </Step>
    </Box>
  );
};

export default BrochureResourceHub;
