export const Products = 'products';
export const ProductCategories = 'productcategories';
export const ProductPrefix = 'product-';
export const UserFundraisingGroups = 'fundraisinggroups';
export const FundraisingGroupTypes = 'fundraisinggrouptypes';
export const RequestInfoTypes = 'requestinfotypes';
export const GroupTypes = 'grouptypes';
export const StatesByCountry = 'statesbycountry';
export const UserAddress = 'useraddress';
export const FundraiserInvite = 'fundraiserInvite';
export const shippingCost = 'shippingCost';
export const Group = 'group';
export const SellerPrefix = 'seller-';
export const SellerDetailPrefix = 'sellerdetail-';
export const OrderPrefix = 'order-';
export const UserInfo = 'userinfo';
export const SellerReport = 'sellerreport-';
export const SellerReportExcel = 'sellerreport-excel';
export const SellerBuyerReport = 'sellerbuyerreport-';
export const FundraiserById = 'fundraiser-';
export const FundraiserFormData = 'formdata';
export const VerifySalesAgent = 'verifysalesagent-';
export const TermsOfService = 'termsofservice';
export const PrivacyPolicy = 'privacypolicy';
export const CampaignPolicy = 'campaignpolicy';
export const TermsOfSale = 'termsofsale';
export const ValidateJoinFundraiser = 'validatejoinfundraiser-';
export const Buyers = 'buyers-';
export const FundraiserRelationships = 'relationships-';
export const fundraiserSummaryReport = 'fundraiersummaryreport-';
export const AllSalesCustomers = 'sales-customers';

export const FilteredFundraiserSummaryReport = 'fundraiser-summary-report';
export const FilteredReport = 'filtered-report';
export const FilteredOrderDetailReport = 'order-detail-report';
export const EmailNotifications = 'email-notifications';
export const FundraiserQRCode = 'fundraiserqrcode';
export const FundraiserSellerQRCode = 'fundraisersellerqrcode';
export const ChairpersonDashboardStatistics = 'chairperson-dashboard-statistics';
export const SellerDashboardStatistics = 'seller-dashboard-statistics';
export const FundraiserOrderSummary = 'fundraiser-order-summary';
export const ExistingCoupons = 'final-order-existing-coupons';
export const FinalOrderSummary = 'final-order-summary';
export const OnlineOrderReport = 'online-order-report';
export const NoDeliveryDate = 'nodeliverydate';
