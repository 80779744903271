import * as React from 'react';
import {
  Flex, Box, Button,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import { useHistory } from 'react-router-dom';

import { Cart } from '../Cart';
import { Arch, useIsArchExpanding } from '../Arch';

import AccountOptionsDesktop from 'ui/account/AccountOptionsDesktop';
import { FundraiserType } from 'features/fundraiser';
import { useChooseLogo } from 'features/fundraiser/hooks/useChooseLogo/useChooseLogo';
import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';
import { useFetchProductsPageRoute } from 'features/products';
import { StatefulLink } from 'ui/components/links';

export interface IDesktopNavProps {
  isAuthenticated: boolean;
  roles: string[];
}

export const DESKTOP_HEADER_HEIGHT = '86px';

// eslint-disable-next-line max-lines-per-function
const Desktop: React.FC<IDesktopNavProps> = ({ roles, isAuthenticated }) => {
  const [ t ] = useTranslation();
  const history = useHistory();
  const { fundraiserType } = useFundraiserState();
  const showHowItWorks = fundraiserType !== FundraiserType.MealDeal;
  const showAboutUs = fundraiserType !== FundraiserType.MealDeal;
  const showStartAFundraiser = fundraiserType !== FundraiserType.MealDeal;
  const startFundraiserUrl = t('nav.StartAFundraiserUrl', { context: fundraiserType });
  const { Logo } = useChooseLogo(fundraiserType);
  const productPageRoute = useFetchProductsPageRoute();
  const isArchExpanding = useIsArchExpanding();
  const [ productPagePathname, productPageSearch ] = productPageRoute.split('?');

  return (
    <Flex
      data-testid="desktop-header"
      sx={ {
        alignItems: 'center',
        flexDirection: 'column',
        display: [ 'none', 'none', 'flex' ],
        position: 'fixed',
        width: '100%',
        zIndex: 1000,
      } }
    >
      <Flex
        sx={ {
          alignItems: 'center',
          boxShadow: !isArchExpanding && '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
          width: '100%',
          bg: 'white',
          height: DESKTOP_HEADER_HEIGHT,
          transition: !isArchExpanding && 'box-shadow 2s linear',
          transitionDelay: '0.5s',
        } }
      >
        <Logo />
        <Flex
          sx={ {
            marginLeft: 'auto',
            alignItems: 'center', alignSelf: 'center', mr: 40,
          } }
        >
          {showHowItWorks && (
            <StatefulLink
              pathname="/how-it-works"
              testId="header-how-it-works"
            >
              {t('nav.HowItWorks')}
            </StatefulLink>
          )}
          <StatefulLink
            pathname={ t('routes.requestInfoPage', { context: fundraiserType }) }
            testId="header-request-info"
          >
            {t('nav.RequestInformation')}
          </StatefulLink>
          {showAboutUs && (
            <StatefulLink pathname="/about-us">
              {t('nav.AboutUs')}
            </StatefulLink>
          )}
          <StatefulLink
            pathname={ productPagePathname }
            persistSearch={ true }
            searchOverride={ productPageSearch }

          >
            {t('nav.OurProducts')}
          </StatefulLink>
          <Box sx={ { ml: '40px', px: '0px !important' } } variant="links.navRouterLink">
            <AccountOptionsDesktop
              isLoggedIn={ isAuthenticated }
              roles={ roles }
            />
          </Box>
          <Box sx={ { mx: 6 } }>
            <Cart />
          </Box>
          {showStartAFundraiser && (
            <Box sx={ { mx: 2 } }>
              <Button
                data-testid="startNewfundraiser"
                onClick={ () => history.push(startFundraiserUrl) }
                sx={ {
                  width: [ '229px', 'initial' ], whiteSpace: 'nowrap', px: 5, letterSpacing: '.4px',
                } }
                variant="buttons.primary.medium"
              >
                START NEW FUNDRAISER
              </Button>
            </Box>
          )}
        </Flex>
      </Flex>
      <Arch headerHeight={ DESKTOP_HEADER_HEIGHT } />
    </Flex>
  );
};

export default Desktop;
