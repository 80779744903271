import React from 'react';
import { ShoppingBag } from 'react-feather';
import { Flex, Box } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import _map from 'lodash/map';

import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';
import { useCartState } from 'features/cart/context/cartContext';
import { RouterLink } from 'ui/common/RouterLink';
import { GoogleEvents } from 'features/analytics';

const Cart = () => {
  const [ t ] = useTranslation();
  const { cart, subtotal, totalQuantity } = useCartState();
  const { fundraiserType } = useFundraiserState();

  const cartRoute = t('routes.cart', { context: fundraiserType });

  const gaViewCartOnClick = () => {
    GoogleEvents.viewCart({
      cartTotal: subtotal,
      products: _map(cart.items, item => ({
        name: item.ProductName,
        price: item.UnitPrice,
        id: item.ProductId.toString(),
        quantity: item.Quantity,
      })),
    });
  };

  return (
    <Flex sx={ { alignItems: 'center' } }>
      <RouterLink
        onClick={ gaViewCartOnClick }
        sx={ {
          color: 'black',
          position: 'relative',
        } }
        to={ {
          pathname: cartRoute,
          state: { fundraiserType: fundraiserType },
        } }
      >
        <ShoppingBag data-testid="shopping_bag" size={ 25 } />
        <Box
          sx={ {
            fontSize: '12px',
            lineHeight: '19px',
            marginBottom: '5px',
            position: 'absolute',
            top: '-5px',
            right: '-10px',
            bg: 'primaryOrange',
            height: '20px',
            width: '20px',
            borderRadius: '50%',
            textAlign: 'center',
            color: 'white',
          } }
        >
          { totalQuantity }
        </Box>
      </RouterLink>
    </Flex>
  );
};

export default Cart;
