/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch } from 'react';
import { toast } from 'react-toastify';

import { AddToCartToast } from 'ui/common/Toast/CustomToasts';
import { Product } from 'features/products';
import { CartItem } from 'features/cart/types';

const addToCart = async(dispatch: Dispatch<any>, product: Product, quantity: number) => {
  try {
    dispatch({ type: 'ADD_ITEM', payload: { product, quantity } });
    toast(<AddToCartToast text={ product.Name } />, { position: 'top-center', autoClose: 3000 });
  } catch (error) {
    console.log(error);
  }
};

const modifyCart = async(dispatch: Dispatch<any>, productId: number, quantity: number) => {
  try {
    dispatch({ type: 'MODIFY_ITEM', payload: { productId, quantity } });
  } catch (error) {
    console.log(error);
  }
};

const clearCart = async(dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: 'CLEAR' });
  } catch (error) {
    console.log(error);
  }
};

const updateCart = (dispatch: Dispatch<any>, items: CartItem[]) => {
  try {
    dispatch({ type: 'UPDATE_CART', items: { items } });
  } catch (error) {
    console.log(error);
  }
};

export {
  addToCart, clearCart, modifyCart, updateCart,
};
