import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ResourceHubPage } from '../ResourceHubPage';
import ResourceHub from '../ResourceHub';

interface MatchParams {
  fundraiserId: string;
}

type ResourceHubPageProps = RouteComponentProps<MatchParams>;

export const BrochureResourceHubEmailTemplatesPage: React.FC<ResourceHubPageProps> = props => (
  <ResourceHubPage { ...props }>
    {({ resourceHubData, fundraiser, sellerSupportGuid }) => (
      <ResourceHub
        data={ resourceHubData }
        fundraiser={ fundraiser }
        fundraiserRole="chairperson"
        sellerSupportGuid={ sellerSupportGuid }
      />
    )}
  </ResourceHubPage>
);
