import {
  Button,
  Flex, IconButton, Modal as SliceModal, Text,
} from '@lce/slice_v2';
import React from 'react';
import { X } from 'react-feather';
import { toast } from 'react-toastify';
import { useTranslation } from '@lce/i18n';

import { useRemoveFundraiserSeller } from 'features/fundraiser';
import { GenericToast } from 'ui/common/Toast/CustomToasts';

export interface IRemoveSellerModal {
  onClose: () => void;
  fundraiserSellerId: number;
  fundraiserId: number;
}

const RemoveSellerModal: React.FC<IRemoveSellerModal> = ({ onClose, fundraiserSellerId, fundraiserId }) => {
  const { mutateAsync: removeFundraiserSeller } = useRemoveFundraiserSeller(fundraiserId);
  const [ t ] = useTranslation();

  const onSubmit = async() => {
    const isRemoveFundraiserSeller = await removeFundraiserSeller(fundraiserSellerId);
    if (isRemoveFundraiserSeller) {
      toast(<GenericToast text={ t('fundraiser.FundraiserSellerRemoveUpdate') } />, { position: 'top-center' });
    } else {
      toast(<GenericToast text={ t('fundraiser.FundraiserSellerRemoveUpdateFail') } />, { position: 'top-center' });
      onClose();
    }
  };

  return (
    <SliceModal
      closeIcon={ false }
      onClose={ onClose }
      sx={ {
        // eslint-disable-next-line max-len
        maxWidth: '700px', borderRadius: '4px', marginTop: [ '120px', '120px', '120px' ], width: [ '90%', '100%', '100%' ],
      } }
    >
      <Flex>
        <IconButton onClick={ onClose } sx={ { cursor: 'pointer', ml: 'auto' } }>
          <X size={ 80 } />
        </IconButton>
      </Flex>

      <Flex sx={ { cursor: 'pointer', ml: 'auto', justifyContent: 'center' } }>
        <Text>
          {' '}
          Are you sure you want to permanently remove this seller?
        </Text>
      </Flex>

      <Flex
        sx={ {
          cursor: 'pointer', ml: 'auto', justifyContent: 'flex-end', mt: '16px',
        } }
      >
        <Button onClick={ onClose } type="button" variant="secondary">
          Cancel
        </Button>

        <Button onClick={ onSubmit } sx={ { ml: '8px' } } type="submit" variant="primary">
          Confirm
        </Button>
      </Flex>
    </SliceModal>
  );
};

export { RemoveSellerModal };
