/* eslint-disable max-lines-per-function */
import React from 'react';
import { Box } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import _map from 'lodash/map';

import FundraiserProgramCard, { IFundraiserProgramCard } from './FundraiserProgramCard';

import { FundraiserType } from 'features/fundraiser';
import { useBooleanFeature } from 'ui/components/hooks';

const FundraiserProgramCards: React.FC = () => {
  const [ t ] = useTranslation();

  const isBrochureLinksEnabled = useBooleanFeature('fr_brochure_navigation_enabled').on;
  const fundraiserTypes: FundraiserType[] = [
    FundraiserType.Brochure,
    FundraiserType.Digital,
    FundraiserType.MealDeal,
  ];

  return (
    <Box
      data-testid="fundraiser-program-cards"
      sx={ {
        display: 'grid',
        gap: [ '16px', '16px', '32px' ],
        gridTemplateAreas: [
          '"BR" "DG" "MD"',
          '"BR BR DG DG" ". MD MD ."',
          '"BR DG MD"',
        ],
        gridTemplateColumns: [ '1fr', '1fr 1fr 1fr 1fr', '1fr 1fr 1fr' ],
        gridTemplateRows: [ 'auto', '1fr 1fr', '1fr' ],
      } }
    >
      {_map(fundraiserTypes, (type) => {
        const programData: IFundraiserProgramCard =
            t('fundraiserPrograms.program', { context: type, returnObjects: true });
        const isBrochureAndBrochureLinksEnabled = type === FundraiserType.Brochure && isBrochureLinksEnabled;
        const urlContext = `${ type }${ isBrochureAndBrochureLinksEnabled ? '-brochureProgramEnabled' : '' }`;
        const fundraiserCreationUrl = t('fundraiserPrograms.fundraiserCreationUrl', { context: urlContext });
        return (
          <FundraiserProgramCard { ...programData } fundraiserCreationUrl={ fundraiserCreationUrl } key={ type } />
        );
      })}
    </Box>
  );
};

export default FundraiserProgramCards;
