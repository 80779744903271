import React from 'react';
import { format, isBefore } from 'date-fns';

import { Seller } from 'features/fundraiser';
import {
  CancelledFundraiserModal,
  EndedFundraiserModal,
  UpcomingFundraiserModal,
} from 'ui/fundraiser/NotRunningFundraisersModals/NotRunningFundraisersModals';

export interface INotRunningFundraiserModalsDisplayProps {
  seller: Seller;
}

export const NotRunningFundraiserModalsDisplay: React.FC<INotRunningFundraiserModalsDisplayProps> = ({ seller }) => {
  const started = !isBefore(new Date(), new Date(seller.StartDate));
  const ended = !isBefore(new Date(), new Date(seller.EndDate));
  const showUpcomingFundraiserModal = !seller.CanSupport && !started && !ended && !seller.IsFundraiserCancelled;
  const showEndedFundraiserModal = !seller.CanSupport && started && ended && !seller.IsFundraiserCancelled;
  const cancelled = !seller.CanSupport && seller.IsFundraiserCancelled;
  const showCancelledFundraiserModal = cancelled || !seller.Active || seller.IsDeleted;

  return (
    <>
      {showUpcomingFundraiserModal && (
        <div data-testid="showUpcomingFundraiserModal">
          <UpcomingFundraiserModal startDate={ format(new Date(seller.StartDate), 'MMMM dd, yyyy') } />
        </div>
      )}
      {showEndedFundraiserModal && (
        <div data-testid="showEndedFundraiserModal">
          <EndedFundraiserModal
            endDate={ format(new Date(seller.EndDate), 'MMMM dd, yyyy') }
          />
        </div>
      )}
      {showCancelledFundraiserModal && (
        <div data-testid="showCancelledFundraiserModal">
          <CancelledFundraiserModal />
        </div>
      )}
    </>
  );
};
