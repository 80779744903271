import { useCallback } from 'react';

import {
  useFetchFundraiserById,
} from 'features/fundraiser/hooks/useFetchFundraiserById';
import { useFundraiserDispatch } from 'features/fundraiser/context/fundraiserContext';
import { FundraiserListItem } from 'features/fundraiser/types/fundraiser';

export const useStatefulFetchFundraiserById = (fundraiserId: string) => {
  const fundraiserDispatch = useFundraiserDispatch();

  const updateFundraiserState = useCallback(({
    Id: fundraiserId,
    FundraiserType: fundraiserType,
  }: FundraiserListItem) => {
    fundraiserDispatch({ type: 'SET_FUNDRAISER_ID', fundraiserId });
    fundraiserDispatch({ type: 'SET_FUNDRAISER_TYPE', fundraiserType });
  }, [ fundraiserDispatch ]);

  return useFetchFundraiserById(parseInt(fundraiserId), updateFundraiserState);
};
