/* eslint-disable max-lines-per-function */
import React from 'react';
import {
  Redirect, Switch, useHistory, useRouteMatch,
} from 'react-router-dom';

import { MealDealProductsPage } from './ProductsPage';
import { MealDealCartPage } from './CartPage';
import { MealDealCheckoutPage } from './CheckoutPage';
import { MealDealOrderConfirmationPage } from './OrderConfirmationPage';
import { MealDealProductDetailPage } from './ProductDetailPage';

import { RouteWrapper } from 'ui/routing';
import { LayoutWithoutFooter } from 'ui/common/Layouts/Layouts';
import { CouponCodeFaqs } from 'pages/coupon-code-faqs';
import RequestInfo from 'pages/requestInfo';
import RequestInfoThankYou from 'pages/requestInfoThankYou';

export const MealDealBuyerRoutes: React.FC = () => {
  const history = useHistory();
  const { url } = useRouteMatch();

  //url = fundraiser/meal-deal/
  return (
    <div data-testid="meal-deal-buyer-routes">
      <Switch>
        <RouteWrapper
          component={ MealDealProductsPage }
          exact={ true }
          history={ history }
          path={ `${ url }/:supportGuid/products` }
          seo={ {
            pageTitle: 'Products | Little Caesars Fundraising',
            linkTitle: 'Little Caesars Fundraising | Click to support my fundraiser!',
          } }
        />
        <RouteWrapper
          component={ MealDealProductsPage }
          exact={ true }
          history={ history }
          path={ `${ url }/products` }
          seo={ {
            pageTitle: 'Products | Little Caesars Fundraising',
            linkTitle: 'Little Caesars Fundraising | Click to support my fundraiser!',
          } }
        />
        <RouteWrapper
          component={ MealDealProductDetailPage }
          exact={ true }
          history={ history }
          layout={ LayoutWithoutFooter }
          path={ `${ url }/:supportGuid/products/:sku` }
          seo={ { pageTitle: 'Product Detail | Little Caesars Fundraising' } }
        />
        <RouteWrapper
          component={ MealDealProductDetailPage }
          exact={ true }
          history={ history }
          layout={ LayoutWithoutFooter }
          path={ `${ url }/products/:sku` }
          seo={ { pageTitle: 'Product Detail | Little Caesars Fundraising' } }

        />
        <RouteWrapper
          component={ MealDealCartPage }
          exact={ true }
          history={ history }
          layout={ LayoutWithoutFooter }
          path={ `${ url }/cart` }
          seo={ { pageTitle: 'Cart | Little Caesars Fundraising' } }
        />
        <RouteWrapper
          component={ MealDealCheckoutPage }
          exact={ true }
          history={ history }
          path={ `${ url }/:supportGuid/checkout` }
          seo={ { pageTitle: 'Checkout | Little Caesars Fundraising' } }
        />
        <RouteWrapper
          component={ MealDealOrderConfirmationPage }
          exact={ true }
          history={ history }
          layout={ LayoutWithoutFooter }
          path={ `${ url }/order-confirmation/:orderGuid` }
          seo={ { pageTitle: 'Order Confirmation | Little Caesars Fundraising' } }
        />
        <RouteWrapper
          component={ CouponCodeFaqs }
          exact={ true }
          history={ history }
          path={ `${ url }/coupon-code-faqs` }
          seo={ { pageTitle: 'Family Meal Deal - Frequently Asked Questions' } }
        />
        <RouteWrapper
          component={ RequestInfo }
          history={ history }
          path={ `${ url }/request-info` }
          seo={ {
            pageTitle: 'Request Info | Little Caesars Fundraising',
            url: 'https://fundraising.littlecaesars.com/request-info',
          } }
        />
        <RouteWrapper
          component={ RequestInfoThankYou }
          history={ history }
          path={ `${ url }/request-info-confirmation` }
          seo={ {
            pageTitle: 'Thank You | Little Caesars Fundraising',
            url: 'https://fundraising.littlecaesars.com/request-info-confirmation',
          } }
        />
        <Redirect to="/404" />
      </Switch>
    </div>
  );
};
