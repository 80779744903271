import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  Box, Flex, Text,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import { GoBackLink } from '../components';

import { DisplayAllGraphics } from './DisplayAllGraphics';

import { FundraiserNavigation } from 'ui/fundraiser/FundraiserNavigation';

interface MatchParams {
  fundraiserId: string;
  customerId?: string;
}

export const DownloadableGraphicsPage: React.FC<RouteComponentProps<MatchParams>> = ({ match }) => {
  const [ t ] = useTranslation();
  return (
    <>
      <FundraiserNavigation fundId={ match.params.fundraiserId } url={ match.url } />
      <Flex data-testid="downloadable-graphics-page" sx={ { flexDirection: 'column', mb: '16px' } }>
        <GoBackLink>
          {t('ResourceHub.BrochureHub.GoBackText')}
        </GoBackLink>
        <Text sx={ { my: '20px' } } variant="header.title">
          {t('ResourceHub.BrochureHub.DownloadableGraphics.Title')}
        </Text>
        <Text sx={ { mb: '20px', display: [ 'block', 'none' ] } } variant="header.form">
          {t('ResourceHub.BrochureHub.DownloadableGraphics.SubTitle')}
        </Text>
        <Box
          sx={ {
            columnCount: [ 2, 3, 3, 4 ], maxWidth: [ '864px' ], mb: '20px',
          } }
        >
          <DisplayAllGraphics />
        </Box>
        <GoBackLink>
          {t('ResourceHub.BrochureHub.GoBackText')}
        </GoBackLink>
      </Flex>
    </>
  );
};
