/* eslint-disable max-lines-per-function */
import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  Box, Button, Flex, Form, Option, Text,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _filter from 'lodash/filter';
import { XCircle } from 'react-feather';

import { Mode, Step } from 'ui/common';
import {
  CouponsAppliedToFinalOrder, ExistingCoupon, useApplyCouponsToFinalOrder,
} from 'features/fundraiser';
import { FinalOrderFormStepState } from 'features/fundraiser/types/fundraiser';
import { ApiError } from 'features/common';

export type ApplyCouponFormProps = {
  fundraiserId: number;
  existingCoupons: ExistingCoupon[];
  testId?: string;
  onEdit: () => void;
  onNext: () => void;
  mode: Mode;
}

export const dataTestIdApplyCouponForm = 'apply-coupon-form-component';

export const ApplyCouponForm: React.FC<ApplyCouponFormProps> = ({
  fundraiserId,
  existingCoupons,
  testId = dataTestIdApplyCouponForm,
  mode,
  onNext,
  onEdit,
}) => {
  const { t } = useTranslation();
  const { mutateAsync: applyFinalOrderCouponsMutation } = useApplyCouponsToFinalOrder();
  const stepTitle = t('dashboard.finalOrderPage.coupons.header');

  const [ selectedCouponCode, setSelectedCouponCode ] = useState<string>('');
  const [ appliedCouponCodes, setAppliedCouponCodes ] = useState<string[]>([]);

  useEffect(() => {
    const appliedCouponsDefault: ExistingCoupon[] = _filter(existingCoupons, coupon => coupon.Applied);
    const appliedCouponCodesDefault: string[] = _map(appliedCouponsDefault, coupon => coupon.Code);
    setAppliedCouponCodes(appliedCouponCodesDefault);
  }, [ existingCoupons ]);

  const isSelectedCouponApplied = useCallback(() => _includes(appliedCouponCodes, selectedCouponCode),
    [ selectedCouponCode, appliedCouponCodes ]);

  const isCouponAlreadyApplied = useCallback(() => selectedCouponCode &&
    appliedCouponCodes.length > 0 && !isSelectedCouponApplied(),
  [ selectedCouponCode, appliedCouponCodes, isSelectedCouponApplied ]);

  const [ disableNextButton, setDisableNextButton ] = useState<boolean>(false);
  const [ errorText, setErrorText ] = useState<string>('');

  const handleAddCoupon = () => {
    setAppliedCouponCodes([ ...appliedCouponCodes, selectedCouponCode ]);
    setSelectedCouponCode('');
  };

  const handleRemoveCoupon = (coupon: string) => {
    setAppliedCouponCodes(appliedCouponCodes.filter(appliedCoupon => appliedCoupon !== coupon));
    setDisableNextButton(false);
  };

  const onSelectCoupon = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCouponCode(e.target.value);
    setDisableNextButton(false);
  };

  const onSubmit = async() => {
    const appliedCoupons = _map(existingCoupons, (coupon) => {
      const Applied = _includes(appliedCouponCodes, coupon.Code);
      return { ...coupon, Applied };
    });

    const data: CouponsAppliedToFinalOrder = { fundraiserId, appliedCoupons };
    try {
      await applyFinalOrderCouponsMutation(data);
      onNext();
    } catch (error) {
      const err = error as ApiError;
      setDisableNextButton(true);
      setErrorText(err.Message);
    }
  };

  return (
    <>
      { mode === FinalOrderFormStepState.Active && (
        <Step title={ stepTitle }>
          <Flex
            data-testid={ testId }
            sx={ { flexDirection: 'column', gap: [ '16px', '32px' ], p: [ '16px', '32px' ] } }
          >
            <Flex sx={ { flexDirection: 'column' } }>
              <Box sx={ { display: [ 'flex', 'grid' ], gridTemplateColumns: '1fr 1fr', gap: [ '8px', '16px' ] } }>
                <Form.Field
                  component={ Form.Select }
                  defaultValue=""
                  id="existingCoupons"
                  label={ t('dashboard.finalOrderPage.coupons.existingCouponsLabel') }
                  name="existingCoupons"
                  onChange={ onSelectCoupon }
                  sx={ { my: '0px', width: '100%' } }
                  type="text"
                  value={ selectedCouponCode }
                >
                  <Option label="" value="" />
                  {_map(existingCoupons, coupon => (
                    <Option key={ coupon.Id } label={ coupon.Code } value={ coupon.Code } />
                  ))}
                </Form.Field>
                <Button
                  data-testId="add-selected-coupon-button"
                  disabled={ appliedCouponCodes.length > 0 || !selectedCouponCode }
                  onClick={ handleAddCoupon }
                  sx={ { alignSelf: 'end', width: 'fit-content' } }
                  variant={ appliedCouponCodes.length > 0 || !selectedCouponCode ? 'disabled' : 'primary' }
                >
                  {t('dashboard.finalOrderPage.coupons.addCoupon')}
                </Button>
              </Box>
              { isSelectedCouponApplied() && (
                <Text variant="dashboard.finalOrderPage.error">
                  {t('dashboard.finalOrderPage.coupons.selectedCouponApplied')}
                </Text>
              )}
              { isCouponAlreadyApplied() && (
                <Text variant="dashboard.finalOrderPage.error">
                  {t('dashboard.finalOrderPage.coupons.onlyOneCoupon')}
                </Text>
              )}
            </Flex>
            <Flex sx={ { flexDirection: 'column', gap: [ '8px', '16px' ] } }>
              <Text variant="dashboard.finalOrderPage.title">
                {t('dashboard.finalOrderPage.coupons.couponsApplied')}
              </Text>
              {_map(appliedCouponCodes, coupon => (
                <Box
                  sx={ { display: 'grid', gridTemplateColumns: [ 'auto 24px', '1fr 1fr' ], gap: [ '8px', '16px' ] } }
                >
                  <Text variant="dashboard.finalOrderPage.body">
                    {coupon}
                  </Text>
                  <Button
                    data-testid={ `${ coupon }-remove-button` }
                    onClick={ () => handleRemoveCoupon(coupon) }
                    sx={ { height: '24px' } }
                    variant="plainText"
                  >
                    <XCircle size={ 24 } />
                  </Button>
                </Box>
              ))}
            </Flex>
            <Box>
              <Button
                data-testId="submit-applied-coupons-button"
                disabled={ disableNextButton }
                onClick={ onSubmit } sx={ { width: '100%' } }
                variant={ disableNextButton ? 'disabled' : 'primary' }
              >
                {t('dashboard.finalOrderPage.coupons.submitButton')}
              </Button>
              { disableNextButton && (
                <Text sx={ { color: 'red' } } variant="dashboard.finalOrderPage.error">
                  {errorText}
                </Text>
              )}
            </Box>
          </Flex>
        </Step>
      )}
      { mode === FinalOrderFormStepState.Preview && (
        <Step onEdit={ onEdit } showEdit={ true } title={ stepTitle } />
      )}
      { mode === FinalOrderFormStepState.Hidden && (
        <Step title={ stepTitle } />
      )}
    </>
  );
};
