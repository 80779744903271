import { useMemo } from 'react';
import { FeatureResult, useFeature } from '@growthbook/growthbook-react';

export function useSafeFeature<T>(feature: string, defaultValue: T): FeatureResult<T> {
  const featureResult = useFeature(feature);
  const featureValue = featureResult.value;

  return useMemo(() => {
    if (!featureValue) {
      return {
        on: false,
        off: true,
        value: defaultValue,
        source: 'unknownFeature',
        ruleId: '',
      };
    }

    return featureResult;
  }, [ featureValue, featureResult, defaultValue ]);
}

export function useBooleanFeature(feature: string): FeatureResult<boolean> {
  return useSafeFeature<boolean>(feature, false);
}

export function useStringFeature(feature: string): FeatureResult<string> {
  return useSafeFeature<string>(feature, '');
}

export function useJSONFeature<T>(feature: string): FeatureResult<T> {
  return useSafeFeature<T>(feature, {} as T);
}

export function useNumberFeature(feature: string): FeatureResult<number> {
  return useSafeFeature<number>(feature, 0);
}
