import {
  Box,
  Button,
  Flex, Form, IconButton, Modal as SliceModal, Option, Text,
} from '@lce/slice_v2';
import * as Yup from 'yup';
import React from 'react';
import { X } from 'react-feather';
import { toast } from 'react-toastify';
import { useTranslation } from '@lce/i18n';

import { GenericToast } from 'ui/common/Toast/CustomToasts';
import { CancelFundraiserRequest, useCancelFundraiser } from 'features/fundraiser';

export interface ICancelFundraiserModal {
  onClose: () => void;
  fundraiserId: number;
  fundraiserName: string;
}

const renderCancellationReasons = () => (
  <>
    <Option value="">---</Option>

    <Option value="created by mistake">Duplicate Fundraiser</Option>

    <Option value="no longer needed">Fundraiser Not Needed</Option>

    <Option value="Group Not Eligible">Group Not Eligible</Option>

    <Option value="Kit Cost">Kit Cost</Option>

    <Option value="Kit Variety">Kit Variety</Option>

    <Option value="No Longer Involved with Group">No Longer Involved with Group</Option>

    <Option value="Profit Not Acceptable">Profit Not Acceptable</Option>

    <Option value="Shipping Charges">Shipping Charges</Option>

    <Option value="Switched to Traditional">Switched to Traditional</Option>

    <Option value="Test Account">Test Account</Option>

    <Option value="Unable to Obtain Approval">Unable to Obtain Approval</Option>

    <Option value="other">Other</Option>
  </>
);

// eslint-disable-next-line max-lines-per-function
const CancelFundraiserModal: React.FC<ICancelFundraiserModal> = ({ onClose, fundraiserId, fundraiserName }) => {
  const { mutateAsync: cancelFundraiser } = useCancelFundraiser();
  const [ t ] = useTranslation();

  const onSubmit = async(data) => {
    const request: CancelFundraiserRequest = {
      fundraiserId: data.FundraiserId,
      cancellationReason: data.CancellationReason === 'other' ? data.CancellationReasonOther : data.CancellationReason,
    };

    const isRemoveFundraiser = await cancelFundraiser(request);
    if (isRemoveFundraiser) {
      toast(<GenericToast text={ t('fundraiser.FudraiserCancel') } />, { position: 'top-center' });
    } else {
      toast(<GenericToast text={ t('fundraiser.FundraiserCancelFailure') } />, { position: 'top-center' });
    }

    onClose();
  };

  const validationSchema = Yup.object().shape({
    CancellationReason: Yup.string().required('A cancellation reason is required'),
    CancellationReasonOther: Yup.string().when('CancellationReason', {
      is: 'other',
      then: Yup.string().required('Please provide a reason').max(255, 'Character limit is 255'),
      otherwise: Yup.string(),
    }),
  });

  return (
    <SliceModal
      closeIcon={ false }
      onClose={ onClose }
      sx={ {
        // eslint-disable-next-line max-len
        maxWidth: '700px', borderRadius: '4px', marginTop: [ '100px', '120px', '120px' ], width: [ '90%', '100%', '100%' ],
      } }
    >
      <Flex sx={ { textAlign: 'center' } }>
        <IconButton onClick={ onClose } sx={ { cursor: 'pointer', ml: 'auto' } }>
          <X size={ 80 } />
        </IconButton>
      </Flex>

      <Form
        initialValues={ {
          FundraiserId: fundraiserId,
          CancellationReason: '',
          CancellationReasonOther: '',
        } }
        onSubmit={ data => onSubmit(data) }
        validationSchema={ validationSchema }
      >
        <Flex
          sx={ {
            flexDirection: 'column',
            padding: [ 0, '15px', '50px 50px' ],
            alignItems: 'center',
          } }
        >

          <Text sx={ { mb: '16px' } } variant="text.header.subUpper">
            Cancel Fundraiser
          </Text>

          <Text
            sx={ {
              mt: '16px',
              mb: '16px',
            } }
          >
            You are about to cancel a fundraiser:
          </Text>

          <Text sx={ { fontWeight: '600' } }>
            {fundraiserName}
          </Text>

          <Text sx={ { fontWeight: '600' } }>
            Fundraiser ID:
            {' '}

            {fundraiserId}
          </Text>

          <Flex sx={ { flexDirection: [ 'column', 'column', 'row' ], width: [ '100%' ] } }>
            <Form.Field
              component={ Form.Select }
              id="CancellationReason"
              label="Choose cancellation reason"
              name="CancellationReason"
              sx={ { mr: [ 0, '12px' ] } }
            >
              {renderCancellationReasons()}
            </Form.Field>

            <Form.Field
              id="CancellationReasonOther"
              label="If other, please describe"
              name="CancellationReasonOther"
              sx={ { ml: [ 0, '12px' ] } }
              variant="forms.fundraiser.field"
            />
          </Flex>
        </Flex>

        <Box
          sx={ {
            mt: '16px',
            mb: '16px',
          } }
        >
          <Flex
            sx={ {
              cursor: 'pointer',
              ml: 'auto',
              justifyContent: 'flex-end',
              flexDirection: [ 'column', 'row' ],
            } }
          >
            <Button onClick={ onClose } type="button" variant="secondary">
              Cancel
            </Button>
            <Button
              data-testid="confirm" ml={ [ 0, '8px' ] } mt={ [ '8px', 0 ] } type="submit"
              variant="primary"
            >
              Confirm
            </Button>
          </Flex>
        </Box>
      </Form>
    </SliceModal>
  );
};

export default CancelFundraiserModal;
