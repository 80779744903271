import React from 'react';
import { useOktaAuth } from '@okta/okta-react';

import Desktop from './Desktop/Desktop';
import Mobile, { joinFundPage } from './Mobile/Mobile';

import { useUserInfo } from 'features/common/hooks/useUserInfo';
import { useMediaQuery } from 'features/common/hooks/useMediaQuery';

const Header: React.FC<{}> = () => {
  const { authState } = useOktaAuth();
  const { data: user } = useUserInfo(authState.isAuthenticated);
  const isLarge = useMediaQuery('(min-width: 1280px)');

  return (
    <header>
      { isLarge
        ? (
          <Desktop
            isAuthenticated={ authState.isAuthenticated }
            roles={ user?.Roles || [] }
          />
        )
        : (
          <Mobile
            isAuthenticated={ authState.isAuthenticated }
            joinFundPage={ joinFundPage }
            roles={ user?.Roles || [] }
          />
        )}
    </header>
  );
};

export default Header;
