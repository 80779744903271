import { useState, useCallback, useEffect } from 'react';
import * as query from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

const useQueryParam = (key: string, partialState?: object) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const location = useLocation();
  const history = useHistory();
  const { pathname, search, state } = location;
  const urlSearchParams = new URLSearchParams(location.search);
  const [ param, setParam ] = useState<any>(urlSearchParams.get(key));

  useEffect(() => {
    if (!param) {
      setParam(query.parse(location.search)[key]);
    }
  }, [ key, location.search, param ]);

  const setQueryString = useCallback((newValue) => {
    setParam(newValue);
    const urlSearchParams = new URLSearchParams(search);
    urlSearchParams.set(key, newValue.toString());
    history.push({
      pathname,
      search: urlSearchParams.toString(),
      state: { ...state, ...partialState },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ history, key ]);

  return [
    param,
    setQueryString,
  ];
};

export default useQueryParam;
